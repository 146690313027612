import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Paper } from '@material-ui/core';


class Home extends Component {

    render() {
        return(
            <div style={{maxWidth:1024, margin:'auto'}}>
                <Paper variant='outlined' style={{padding:10}}>
                    <img src="/images/test.jpg" width='100%' height='80%' alt="" />
                    <p>
                        欢迎访问，如已有帐号请
                        <Button variant='text' onClick={()=>this.props.history.push('/login')}>登录</Button>，
                        如没有帐号，请
                        <Button variant='text' onClick={()=>this.props.history.push('/register')}>注册</Button>。
                    </p>
                    <p>
                        注意：本系统建议使用火狐浏览器或谷歌浏览器访问，进入考试页面需<b>开启浏览器访问摄像头权限</b>。
                    </p>
                </Paper>
            </div>
        )
    }
}


export default withRouter(Home);