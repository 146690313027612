import React, { Component, } from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import Home from './Home';
import ExamPage from './ExamPage';
import Register from './Register';
import Login from './Login';
import UserHome from './UserHome';
import AdminHome from './AdminHome';
import ExamRecords from './ExamRecords';
import ExamPaper from './Paper';
import UserInfo from './UserInfo';
import Enrolls from './Enrolls';
import ExamPhotoes from './ExamPhotoes';
import ManageForm from './ManageForm';
import GetUsername from './GetUsername';
import EnrollStatistics from './EnrollStatistics';
import EnrollSearch from './EnrollSearch';
import RequestResetPasswd from './RequestResetPasswd';
import ResetPasswd from './ResetPasswd';
import Statistics from './Statistics';
import ChangeUserInfo from './ChangeUserInfo';
import UserRole from './UserRole';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import Ads from './Ads';
import { BACK_URL } from './Utils';

import ReactGA from 'react-ga';
ReactGA.initialize('G-4JD46S2M2C');


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            access: '',
            refresh: '',
            name: '',
            tester_id: '',
            company: '',
            roles: '',
            is_staff: '',
            email: '',
            tel: '',
        }
    }

    onLoginInputChange = (evt) => {
        const id = evt.target.id;
        this.setState({[id]:evt.target.value.trim(),});
        if (id==='username'){
          document.cookie = `username=${evt.target.value.trim()}`;
        }
    }

    getUserInfo = (access) => {
        var myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer ' + access);
        fetch(BACK_URL+'get_user_info/',{headers: myHeaders})
            .then(res=>res.json())
            .then(data=>{
                this.setState({
                    tester_id: data.id,
                    name: data.name,
                    company: data.company,
                    roles: data.roles,
                    is_staff: data.is_staff,
                    email: data.email,
                    tel: data.tel,
                })
            }).catch(err=>{
                console.log(err);
            })
      }

    onLoginClicked = () => {
        const options = {
          method: 'POST',
          body: JSON.stringify(
            {
              username: this.state.username,
              password: this.state.password,
              token: {}
            }
          ),
          headers: {
            'Content-Type': 'application/json'
          }
        };
        fetch(BACK_URL+'auth-token/', options)
            .then(res=>res.json())
            .then(res=>{
              if ('access' in res && 'refresh' in res) {
                this.setState({
                    access: res.access, 
                    refresh: res.refresh
                }, function(){
                    this.getUserInfo(res.access);                
                });
              } else {
                alert('登录失败，请确定您的用户名和密码正确，您可以找回用户名或通过注册使用的邮箱找回密码。')
              }
            })
      }

    onLogout = () => {
        this.setState({
            access:'', 
            refresh: '', 
            username: '', 
            password: '', 
            name: '', 
            company: '', 
            roles: '', 
            is_staff: '',
            tester_id: 0,
            email: '',
            tel: '',
        });
    }

    render() {
        const name = this.state.name;
        const is_staff = this.state.is_staff;
        return <Router>
            <Header 
                name={name?name:''}
                is_staff={is_staff}
                onLogout={this.onLogout}
            />
            <Switch>
                <Route exact path='/login' render={
                    props=><Login 
                        access={this.state.access}
                        is_staff={this.state.is_staff}
                        tester_id={this.state.tester_id}
                        onLoginInputChange={this.onLoginInputChange} 
                        onLoginClicked={this.onLoginClicked} 
                    />}
                />
                <Route exact path='/request_reset_passwd' render={
                    props=><RequestResetPasswd />
                } />
                <Route exact path='/reset_passwd' render={
                    props=><ResetPasswd />
                } />
                <Route exact path='/exam' render={
                    props=><ExamPage 
                        access={this.state.access} 
                        name={this.state.name}
                        company={this.state.company}
                        roles={this.state.roles}
                    />
                } />
                <Route exact path='/exam_records' render={
                    props=><ExamRecords access={this.state.access}
                    />
                } />
                <Route exact path='/exam_paper' render={
                    props=><ExamPaper access={this.state.access} />
                } />
                <Route exact path='/register' component={Register} />
                <Route exact path='/userhome' render={
                    props=><UserHome 
                        access={this.state.access} 
                        roles={this.state.roles} 
                        tester_id={this.state.tester_id}
                        is_staff={this.state.is_staff}
                    />
                } />
                <Route exact path='/userrole' render={
                    props=><UserRole 
                        access={this.state.access} 
                        roles={this.state.roles} 
                        tester_id={this.state.tester_id}
                        is_staff={this.state.is_staff}
                        name={this.state.name}
                        company={this.state.company}
                        onLogout={this.onLogout}
                    />
                } />
                <Route exact path='/adminhome' render={
                    props=><AdminHome access={this.state.access} />
                } />
                <Route exact path='/userinfo' render={
                    props=><UserInfo 
                        access={this.state.access} 
                        tester_id={this.state.tester_id} 
                        is_staff={this.state.is_staff}
                        name={this.state.name}
                        company={this.state.company}
                        roles={this.state.roles}
                        username={this.state.username}
                    />
                } />
                <Route exact path='/change_user_info' render={
                    props=><ChangeUserInfo 
                        access={this.state.access} 
                        tester_id={this.state.tester_id} 
                        is_staff={this.state.is_staff}
                        name={this.state.name}
                        company={this.state.company}
                        roles={this.state.roles}
                        email={this.state.email}
                        tel={this.state.tel}
                        username={this.state.username}
                        onLogout={this.onLogout}
                    />
                } />
                <Route exact path='/enrolls' render={
                    props=><Enrolls access={this.state.access} />
                } />
                <Route exact path='/enroll_statistics' render={
                    props=><EnrollStatistics access={this.state.access} />
                } />
                <Route exact path='/enroll_search' render={
                    props=><EnrollSearch access={this.state.access} />
                } />
                <Route exact path='/manage' render={
                    props=><ManageForm access={this.state.access} name={this.state.name} />
                } />
                <Route exact path='/photoes' render={
                    props=><ExamPhotoes access={this.state.access} />
                } />
                <Route exact path='/statistics' render={
                    props=><Statistics access={this.state.access} />
                } />
                <Route exact path='/getusername' render={
                    props=><GetUsername/>
                } />
                <Route exact path='/' render={
                    props=><Home 
                        access={this.state.access} 
                        is_staff={this.state.is_staff} 
                    />}
                />
            </Switch>
            <Footer/>
        </Router>
    }
}

export default App;