import React, { Component } from 'react';
//import { CustomPlaceholder, PhotoPlaceholder } from 'react-placeholder-image';
import { Paper, Grid, } from '@material-ui/core';
import ad1_400_100 from './images/ad1_400_100.png';

//const Placeholder = () => <PhotoPlaceholder 
//    width={400}
//    height={100}
///>

class Ads extends Component {

    render() {
        return (
            <Paper style={{widhth:'90%', margin:'auto'}} align="center">
                <Grid container spacing={1} style={{marginTop:'5px', marginBottom:'5px'}}>
                    <Grid item xs={12} sm={6} md={3}>
                        <img src={ad1_400_100} alt='400x100广告位1'/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <img src={ad1_400_100}alt='400x100广告位2'/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <img src={ad1_400_100}alt='400x100广告位3'/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <img src={ad1_400_100} alt='400x100广告位4' />
                    </Grid>
                </Grid>
            </Paper>
        )
    }

}

export default Ads;