import React, { Component } from 'react';
import dateFormat from 'dateformat';
import { withRouter } from 'react-router-dom';
import { BACK_URL } from './Utils';
import {
    Card, CardContent, Typography, Paper,
    Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow, Button, TableFooter,
    Box, Tabs, Tab, Alert, TextField,
} from '@material-ui/core';
//import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SearchIcon from "@material-ui/icons/Search";
import CloudUploadSharp from '@material-ui/icons/CloudUploadSharp';
import CloudDownloadSharp from '@material-ui/icons/CloudDownloadSharp';

import ReactGA from 'react-ga';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

class AdminHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exams: [],
            question_bank: [],
            roles: [],
            companies: [],
            tabValue: 0,
            alertContent: null,
            cname: '',
        }
    }

    componentDidMount() {
        this.fetch_exams();
        this.fetch_question_banks();
        this.fetch_roles();
        this.fetch_companies();
    }

    tabChange = (event, newValue) => {
        this.setState({tabValue: newValue});
    }

    fetch_exams = (url=null) => {
        var myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        fetch(url === null ? BACK_URL+'exams/' : url, {headers: myHeaders})
            .then(res=>{ if (res.ok) {
                return res.json()
            } else {
                this.props.history.push('login');
            }})
            .then(res =>{
                this.setState({exams:res,});
            })
            .catch(err=>{
                console.log(err);
            })
    }

    fetch_question_banks = (url=null) => {
        var myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        fetch(url === null ? BACK_URL + 'question_bank/' : url, {headers: myHeaders})
            .then(res=>{ if (res.ok) {
                return res.json()
            } else {
                this.props.history.push('login');
            }})
            .then(res =>{
                this.setState({question_bank:res,});
            })
            .catch(err=>{
                console.log(err);
            })
    }

    fetch_roles = (url = null) => {
        var myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        fetch(url === null ? BACK_URL + 'role_list/' : url, {headers: myHeaders})
            .then(res=>{ if (res.ok) {
                return res.json()
            } else {
                this.props.history.push('login');
            }})
            .then(res =>{
                this.setState({roles:res,});
            })
            .catch(err=>{
                console.log(err);
            })
    }

    fetch_companies = (url=null, cname=null) => {
        var myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        let _url = '';
        if (url === null) {
            _url = BACK_URL + 'companies/';
        } else {
            _url = url;
        }
        if (cname !== null) {
            _url = _url + '?cname=' + cname;
        }
        fetch(_url, { headers: myHeaders })
          .then(res=>{ if (res.ok) {
              return res.json()
          } else {
              this.props.history.push('login');
          }})
          .then(res=>{
              this.setState({companies: res,});
          })
          .catch(err=>{
              console.log(err);
          })
    }

    componentDidUpdate() {
        if (!this.props.access) this.props.history.push('/login');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    viewExamRecord = (id, cnt) => {
        //console.log(cnt);
        if (cnt > 0) this.props.history.push({
            pathname: '/exam_records',
            state: {examId: id,}
        })
    }

    viewEnrollClicked = (id, cnt, name) => {
        if (cnt > 0) this.props.history.push({
            //pathname: '/enrolls',
            pathname: '/enroll_statistics',
            state: {exam_id: id, name:name}
        })
    }

    queryEnrollClicked = (id, cnt, exam_name) => {
        if (cnt > 0) this.props.history.push({
            pathname: '/enroll_search',
            state: {exam_id:id, name:exam_name}
        })
    }

    viewPhotoesClicked = (id, exam_name) => {
        this.props.history.push({
            pathname: '/photoes',
            state: {exam_id: id, name: exam_name,}
        })
    }

    deleteRole = (id) => {
        //<DeleteForeverIcon onClick={()=>this.deleteRole(role.id)} />
        if (window.confirm('你确定要删除这个角色吗?')) {
            var myHeaders = new Headers();
            myHeaders.append('Authorization','Bearer ' + this.props.access);
            myHeaders.append('Content-Type','application/json;charset=UTF-8');
            const options = {
                method: "DELETE",
                headers: myHeaders,
            };
            fetch(BACK_URL+'role_delete/'+id+'/', options)
                .then(res=>{
                    if (res.status===204) {
                        this.fetch_roles();
                    } else {
                        alert('未能删除角色，请联系管理员。')
                    }
                });
            } 
    }

    exportPool = (pool_id) => {
        if (window.confirm('您想导出该题库的试题吗？')) {
            console.log('should export to cvs file.')
        }
    }

    importPool = (pool_id) => {
        if  (window.confirm('您想导入该题库的试题吗？已有数据将被完全覆盖。')) {
            const selectedFile = document.getElementById('file_'+pool_id).files[0];
            if (selectedFile) {
                this.setState({alertContent:'正在上传并导入数据，这可能需要几分钟的时间，请勿关闭或刷新本页面...'})
                let formData = new FormData();
                formData.append("file", selectedFile);
                fetch(BACK_URL+'csv_upload/'+pool_id+'/', {method: "POST", body: formData})
                  .then(res=>{
                      this.setState({alertContent:'题库上传并导入完毕。'})
                      console.log(res)
                  });
            } else {
                alert('您没有选择任何文件。')
            }
        } 
    }

    onCNameChange = (evt) => {
        const id = evt.target.id;
        this.setState({[id]: evt.target.value.trim(),});
    }

    cnameSearchClicked = () => {
        const cname = this.state.cname;
        if (cname.trim() !== '') {
            this.fetch_companies(null, cname);
        } else {
            this.fetch_companies(null, null);
        }
    }

    render() {
        const exams = this.state.exams;
        let results1 = [];
        if (exams.results !== undefined && exams.results.length > 0) {
            exams.results.forEach(exam=>{
                // 注意：此处假设考试中只有一种企业类型
                let role = exam.role1;
                const company_type = role.split('-')[0] + '-';
                role = role.slice(0, 1) + role.slice(1).replaceAll(company_type, '');
                results1.push(
                    <TableRow key={exam.id}>
                        <TableCell><Button onClick={()=>this.viewPhotoesClicked(exam.id, exam.name)} variant="text">{exam.name}</Button></TableCell>
                        <TableCell>{role}</TableCell>
                        <TableCell>{exam.duration}</TableCell>
                        <TableCell>{dateFormat(exam.mock_exam_begin,"yy-mm-dd")}</TableCell>
                        <TableCell>{dateFormat(exam.mock_exam_end,"yy-mm-dd")}</TableCell>
                        <TableCell>{dateFormat(exam.exam_begin,"yy-mm-dd")}</TableCell>
                        <TableCell>{dateFormat(exam.exam_end,"yy-mm-dd")}</TableCell>
                        <TableCell><Button onClick={()=>this.viewEnrollClicked(exam.id, exam.enrolled_cnt, exam.name)} variant="text">{exam.enrolled_cnt}</Button></TableCell>
                        <TableCell>{exam.record_cnt}</TableCell>
                        <TableCell><SearchIcon onClick={()=>this.queryEnrollClicked(exam.id, exam.enrolled_cnt, exam.name)} /></TableCell>
                    </TableRow>
                )
            })
        }

        const question_bank = this.state.question_bank;
        let results2 = [];
        if (question_bank.results !== undefined && question_bank.results.length > 0) {
            question_bank.results.forEach(qb=>{
                results2.push(
                    <TableRow key={qb.id}>
                        <TableCell>{qb.name}</TableCell>
                        <TableCell>{qb.creater}</TableCell>
                        <TableCell>{dateFormat(qb.created,"yy-mm-dd")}</TableCell>
                        <TableCell>{qb.question_cnt}</TableCell>
                        <TableCell>
                            {
                                qb.question_cnt === 0 ? 
                                <div>
                                    <input type="file" id={'file_'+qb.id} accept=".csv" />
                                    <CloudUploadSharp onClick={()=>this.importPool(qb.id)} />
                                </div>
                                :
                                <div>
                                    <input type="file" id={'file_'+qb.id} accept=".csv" />
                                    <CloudUploadSharp onClick={()=>this.importPool(qb.id)} />
                                    {'    '}
                                    <CloudDownloadSharp onClick={()=>this.exportPool(qb.id)} />
                                </div>
                            } 
                        </TableCell>
                    </TableRow>
                )
            })
        }

        const roles = this.state.roles;
        let results3 = [];
        if (roles.results !== undefined && roles.results.length > 0) {
            roles.results.forEach(role=>{
                results3.push(
                    <TableRow key={role.id}>
                        <TableCell>{role.company_type}</TableCell>
                        <TableCell>{role.name}</TableCell>
                        <TableCell>
                        </TableCell>
                    </TableRow>
                )
            })
        }

        const companies = this.state.companies;
        let results4 = [];
        if (companies.results !== undefined && companies.results.length > 0) {
            companies.results.forEach((c,index)=>{
                results4.push(
                    <TableRow key={c.id}>
                        <TableCell>{index+1}</TableCell>
                        <TableCell>{c.cred_no}</TableCell>
                        <TableCell>{c.name}</TableCell>
                        <TableCell>{c._type}</TableCell>
                        <TableCell>{c.active?'是':'否'}</TableCell>
                    </TableRow>
                )
            })
        }

        return (
            <div style={{width:'90%', margin:'auto'}}>
                <Box style={{border:1}}>
                    <Tabs value={this.state.tabValue} onChange={this.tabChange} aria-label="basic tabs example">
                        <Tab label="考试" id="exam" aria-controls="simple-tabpanel" />
                        <Tab label="题库" id="pool" aria-controls="simple-tabpanel" />
                        <Tab label="角色" id="role" aria-controls="simple-tabpanel" />
                        <Tab label="企业" id="companies" aria-controls="simple-tabpanel" />
                    </Tabs>
                </Box>
                <TabPanel value={this.state.tabValue} index={0}>
                    <Card variant="outlined" style={{width:'100%', margin:'auto'}}>
                        <CardContent>
                            <Table>
                            <TableContainer component={Paper}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>名称</TableCell>
                                        <TableCell>参加人员</TableCell>
                                        <TableCell>时长(分钟)</TableCell>
                                        <TableCell>练习开始时间</TableCell>
                                        <TableCell>练习结束时间</TableCell>
                                        <TableCell>考试开始时间</TableCell>
                                        <TableCell>考试结束时间</TableCell>
                                        <TableCell>报名情况</TableCell>
                                        <TableCell>考试记录</TableCell>
                                        <TableCell>操作</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { results1 }
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell>
                                        <Button
                                            onClick={()=>this.props.history.push({
                                                pathname:'/manage',
                                                state: {
                                                    '_type':'exam',
                                                    'id':2,
                                                }
                                        })}
                                        >新增考试</Button>
                                        </TableCell>
                                        <TableCell>
                                        <Button
                                            style={{ marginLeft: '10px' }}
                                            onClick={()=>this.props.history.push({
                                                pathname:'/statistics',
                                        })}
                                        >企业参与培训统计</Button>
                                        </TableCell>
                                        <TableCell colSpan={4}>{exams.count}个结果</TableCell>
                                        <TableCell colSpan={2}>
                                            <Button variant='text' disabled={!exams.previous} onClick={()=>this.fetch_exams(exams.previous)}>上一页</Button>
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            <Button variant='text' disabled={!exams.next} onClick={()=>this.fetch_exams(exams.next)}>下一页</Button>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </TableContainer>
                            </Table>
                        </CardContent>
                    </Card>
                </TabPanel>
                <TabPanel value={this.state.tabValue} index={1}>
                    <Card variant="outlined" style={{width:'100%', margin:'auto', marginTop:'5px'}}>
                        <CardContent>
                            { 
                            this.state.alertContent && 
                            <Alert onClose={()=>this.setState({alertContent:null})} severity="warning">{this.state.alertContent}</Alert>
                            }
                            <Table>
                            <TableContainer component={Paper}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>名称</TableCell>
                                        <TableCell>创建者</TableCell>
                                        <TableCell>创建时间</TableCell>
                                        <TableCell>试题数量</TableCell>
                                        <TableCell>操作</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { results2 }
                                </TableBody>
                                <TableFooter>
                                    <TableCell>
                                    <Button
                                        style={{ flex: 1 }}
                                        onClick={()=>this.props.history.push({
                                            pathname:'/manage',
                                            state: {
                                                '_type':'pool',
                                                'id':1,
                                            }
                                    })}
                                    >新增题库</Button>
                                    </TableCell>
                                    <TableCell colSpan={2}>{question_bank.count}个结果</TableCell>
                                    <TableCell>
                                            <Button variant='text' disabled={!question_bank.previous} onClick={()=>this.fetch_question_banks(question_bank.previous)}>上一页</Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button variant='text' disabled={!question_bank.next} onClick={()=>this.fetch_question_banks(question_bank.next)}>下一页</Button>
                                        </TableCell>
                                </TableFooter>
                            </TableContainer>
                            </Table>
                        </CardContent>
                    </Card>
                </TabPanel>
                <TabPanel value={this.state.tabValue} index={2}>
                    <Card variant="outlined" style={{width:'100%', margin:'auto', marginTop:'5px'}}>
                        <CardContent>
                            <Table>
                            <TableContainer component={Paper}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>企业类型</TableCell>
                                        <TableCell>角色名称</TableCell>
                                        <TableCell>操作</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { results3 }
                                </TableBody>
                                <TableFooter>
                                    <TableCell>
                                    <Button  
                                        style={{ flex: 1 }}
                                        onClick={()=>this.props.history.push({
                                            pathname:'/manage',
                                            state: {
                                                '_type':'role',
                                                'id':0,
                                                'company_type': '',
                                                'name': '',
                                            }
                                    })}
                                    >新增角色</Button></TableCell>
                                    <TableCell>
                                        <Button variant='text' disabled={!roles.previous} onClick={()=>this.fetch_roles(roles.previous)}>上一页</Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button variant='text' disabled={!roles.next} onClick={()=>this.fetch_roles(roles.next)}>下一页</Button>
                                    </TableCell>
                                </TableFooter>
                            </TableContainer>
                            </Table>
                        </CardContent>
                    </Card>
                </TabPanel>
                <TabPanel value={this.state.tabValue} index={3}>
                    <Card variant="outlined" style={{width:'100%', margin:'auto', marginTop:'5px'}}>
                        <CardContent>
                            <Table>
                            <TableContainer component={Paper}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>序号</TableCell>
                                        <TableCell>统一社会信用代码</TableCell>
                                        <TableCell>
                                            企业名称
                                            <TextField 
                                                defaultValue={''} 
                                                id='cname' 
                                                onChange={this.onCNameChange}
                                                size='small'
                                            />
                                            <SearchIcon 
                                                onClick={()=>this.cnameSearchClicked()} 
                                            />
                                        </TableCell>
                                        <TableCell>企业类型</TableCell>
                                        <TableCell>是否有效</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { results4 }
                                </TableBody>
                                <TableFooter>
                                    <TableCell>
                                    <Button  
                                        style={{ flex: 1 }}
                                        onClick={()=>this.props.history.push({
                                            pathname:'/manage',
                                            state: {
                                                '_type':'company',
                                                'id':3,
                                            }
                                    })}
                                    >新增企业</Button></TableCell>
                                    <TableCell colSpan={2}>{companies.count}个结果</TableCell>
                                    <TableCell>
                                        <Button variant='text' disabled={!companies.previous} onClick={()=>this.fetch_companies(companies.previous)}>上一页</Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button variant='text' disabled={!companies.next} onClick={()=>this.fetch_companies(companies.next)}>下一页</Button>
                                    </TableCell>
                                </TableFooter>
                            </TableContainer>
                            </Table>
                        </CardContent>
                    </Card>
                </TabPanel>
            </div>
        )
    }
}


export default withRouter(AdminHome);