import React, { Component } from 'react';
import { 
  TextField, 
  Button, 
  Paper, Grid 
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { BACK_URL } from './Utils';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

const styles = {
    Paper: {
        padding: '10px',
        width: '90%',
        margin: 'auto',
    }
}

class GetUsername extends Component {

    constructor(props) {
        super(props);
        this.state = {
            company: '',
            name: '',
            username: '',
            email: '',
            tel:'',
            err: '',
        }
    }

    componentDidMount() {
        loadCaptchaEnginge(4); 
    }

    onInputChange = (evt) => {
        const id = evt.target.id;
        this.setState({[id]: evt.target.value.trim(),});
    }

    onSubmit = () => {
        let user_captcha_value = document.getElementById('captcha').value;
        if (validateCaptcha(user_captcha_value)===false) {
            alert('校验码不匹配！');
            return;
        }
        const company = this.state.company;
        const name = this.state.name;
        if (company.trim() === '' || name.trim() === ''){
            alert('公司名称和姓名不可为空');
            return;
        } 
        fetch(BACK_URL+'get_username/?company='+company+'&name='+name)
          .then(
              response=>response.json()
              .then(data=>({
                  data: data,
                  status: response.status,
              })).then(res=>{
                      this.setState({'err':'','username':res.data.username,'email':res.data.email,'tel':res.data.tel,});
              })).catch(err=>{
                  //console.log('err', err);
                  this.setState({'err':'未查询到用户信息!','username':'','email':'','tel':''})
              });
    }

    render() {
        var results = [];
        const username = this.state.username;
        const tel = this.state.tel;
        const email = this.state.email;
        const err = this.state.err;
        //console.log(status);
        if (username !== '') {
            results.push(
                <div>
                    <p>用户名：<b>{username}</b></p>
                    <p>电话：<b>{tel}</b></p>
                    <p>邮箱：<b>{email}</b></p>
                </div>
            )
        } else {
            if (err !== '') {
                results.push(
                    <div>
                        <p style={{'color':'red'}}>{err}</p>
                    </div>
                )
            }
        }
        return(
            <Paper variant='outlined' style={styles.Paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            id="company"
                            label="公司全称"
                            defaultValue={''}
                            onChange={this.onInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            id="name"
                            label="姓名"
                            defaultValue={''}
                            onChange={this.onInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadCanvasTemplateNoReload />
                            <TextField
                            required
                            id="captcha"
                            label="请输入图片中的字母和数字"
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant='contained' onClick={this.onSubmit} style={{'marginRight':'10px'}}>
                            获取用户名
                        </Button>
                        <Button variant='contained' color="secondary" onClick={()=>this.props.history.push('/register')}>
                            没有账户？点我注册
                        </Button>
                    </Grid>
                    <hr />
                    <Grid item xs={12}>
                        { results }
                    </Grid>
                </Grid>
            </Paper>
        )
    }
}

export default withRouter(GetUsername);