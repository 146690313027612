export function getCookie(name) {
  var arr = document.cookie.match(new RegExp("(^| )" + name + "=([^;]*)(;|$)"));
  if (arr != null) return unescape(arr[2]); return null;
};


export function setCookie(name,value)
{
    var Days = 30;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days*24*60*60*1000);
    document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString();
} 

// Quick and simple export target #table_id into a csv
export  function download_table_as_csv(table_id, separator = ',') {
    // Select rows from table_id
    var rows = document.querySelectorAll('table#' + table_id + ' tr');
    // Construct csv
    var csv = [];
    for (var i = 0; i < rows.length; i++) {
        var row = [], cols = rows[i].querySelectorAll('td, th');
        for (var j = 0; j < cols.length; j++) {
            // Clean innertext to remove multiple spaces and jumpline (break csv)
            var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
            // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
            data = data.replace(/"/g, '""');
            // Push escaped string
            row.push('"' + data + '"');
        }
        csv.push(row.join(separator));
    }
    var csv_string = csv.join('\n');
    // Download it
    var filename = 'export_' + table_id + '_' + new Date().toLocaleDateString() + '.csv';
    var link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function exportJSONToCSV(objArray) {
  var arr = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  var str =
    `${Object.keys(arr[0])
      .map((value) => `"${value}"`)
      .join(',')}` + '\r\n';
  var csvContent = arr.reduce((st, next) => {
    st +=
      `${Object.values(next)
        .map((value) => `"${value}"`)
        .join(',')}` + '\r\n';
    return st;
  }, str);
  var element = document.createElement('a');
  element.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
  element.target = '_blank';
  element.download = 'export.csv';
  element.click();
}

//export const BACK_URL = 'http://148.100.79.116:8000/';
//export const BACK_URL = 'http://127.0.0.1:8000/';
export const BACK_URL  = 'https://gmpback.yaoyoupei.com/';
//export const BACK_URL  = 'https://back.tinyexam.net/';