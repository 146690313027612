import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { BACK_URL } from "./Utils";
import {
    Card, CardContent, Typography, Paper, Grid, Button
} from '@material-ui/core';


class Photo extends Component {
    render() {
        return (
            <Paper style={{'padding':'5px', 'width':'320px', 'height':'280px', 'margin':'5px'}}>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{'border':'solid', 'borderWidth':'1px', 'borderColor':'blue','textAlign':'center'}}>
                            <img src={this.props.photo_url} alt='user' height={'210px'}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{'textAlign':'center'}}>
                        <p>{this.props.tester_company + '  ' + this.props.tester_name}</p>
                    </Grid>
                </Grid>
            </Paper>
        )
    }
}


class ExamPhotoes extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            photoes: [],
        }
    }

    componentDidMount() {
        if (this.props.access === '') this.props.history.push('/login');
        //const exam_record_id = this.props.location.state.exam_record_id;
        //const exam_name = this.props.location.state.exam_name;
        this.fetchPhotoes();
    }

    fetchPhotoes = () => {
        const exam_id = this.props.location.state.exam_id;
        var myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        fetch(BACK_URL + 'exam_photo_sample/' + exam_id + '/', {headers: myHeaders})
            .then(res=>res.json())
            .then(res=>{
                this.setState({photoes: res,})
            })
            .catch(err=>console.log(err));
    }

    componentDidUpdate() {
        if (!this.props.access) this.props.history.push('/login');
    }

    render() {
        const photoes = this.state.photoes;
        let results = [];
        if (photoes !== undefined && photoes.length > 0) {
            photoes.forEach(photo=>{
                results.push(
                    <Grid item xs={12} sm={6} md={4} lg={3} key={photo.id}>
                        <Photo key={photo.id} photo_url={photo.photo} tester_name={photo.tester_name} tester_company={photo.tester_company} />
                    </Grid>
                )
            })
        }
     
        return (
            <Card variant="outlined" style={{width:'80%', margin:'auto'}}>
                <CardContent style={{'textAlign':'center'}}>
                    <Typography gutterBottom variant="h5" component="div" style={{'margin':'5px'}}>
                        {this.props.location.state.name} <Button variant="text" onClick={this.fetchPhotoes}>Refresh</Button>
                    </Typography>
                    <hr />
                    <Grid container>
                        { results }
                    </Grid>
                </CardContent>
            </Card>
        )
    }
}

export default withRouter(ExamPhotoes);