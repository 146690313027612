import Webcam from 'react-webcam';
import React from 'react';
import { BACK_URL } from './Utils';


const videoConstraints = {
    width: '50%',
    facingMode: "user"
  };
  
const WebcamCapture = (props) => {
    const webcamRef = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(null);

    function imgLoad(){
      const record_id = props.record_id;
      //let form_data = new FormData();
      var myHeaders = new Headers();
      const img = document.getElementById('imgCaptured');
      myHeaders.append('Authorization', 'Bearer ' + props.access);
      myHeaders.append('Content-Type', 'application/json');
      //form_data.append('exam_record', record_id);
      //form_data.append('photo', img.src);
      const options = {
          method: 'POST',
          //body: form_data,
          body: JSON.stringify({
              exam_record: record_id,
              photo: img.src
          }),
          headers: myHeaders
      }
      if (img.src.length > 0) {
        //console.log(img.src);
        fetch(BACK_URL+'exam_record_photos/', options)
        .then(res=>res.json())
        .catch(err=>console.log(err))
    }
  }
  
    const capture = React.useCallback(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      //console.log(typeof(imageSrc));
      setImgSrc(imageSrc);
    }, [webcamRef, setImgSrc]);

    return (
        <>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          />
          <button id="btnCapture" onClick={(e)=>{e.preventDefault();capture();}} style={{display: 'none'}}>Capture</button>
          <img id="imgCaptured" src={imgSrc} alt="captured" onLoad={imgLoad} style={{display: 'none'}}/>
        </>
      );
};

export default WebcamCapture;