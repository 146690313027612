import React, { Component } from 'react';
import moment from 'moment';
import Countdown, { zeroPad } from 'react-countdown';
import { BACK_URL } from './Utils';
import { 
    Grid, 
    Paper, 
    Typography, 
    Box, 
    Button,
    FormControl, 
    FormGroup, 
    FormControlLabel, 
    Checkbox, 
    RadioGroup, 
    Radio,
    Alert,
    AlertTitle,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import WebcamCapture from './Capture';
//import { topics } from '../store';

import ReactGA from 'react-ga';

const CAPTURE_FREQUENCY = 5;

class ExamPage extends Component {
    state = {
        topics: [],
        id: 1,
        answers: [],
        answer: [],
        radioSelected: 0,
        modalShow: false,
        score: 0,
        exam_id: -1,
        single_choice_val: 0,
        mutiple_choice_val: 0,
        judge_val: 0,
        pass_val: 0,
        _now: Date.now(),
        duration_passed: 0,
    }

    componentDidMount() {
        const examId = this.props.location.state.examId;
        const duration = this.props.location.state.duration;
        var myHeaders = new Headers();
        const access = this.props.access;
        myHeaders.append('Authorization','Bearer ' + access);
        fetch(BACK_URL+'get_questions/'+examId, {headers:myHeaders})
            .then(res=>res.json())
            .then(res=>{
                this.setState({
                    in_judge: res.in_judge,
                    topics: res.data,
                    record_id: res.record_id,
                    id: res.current_topic,
                    exam_id: res.exam_id,
                    single_choice_val: res.single_choice_val,
                    multiple_choice_val: res.multiple_choice_val,
                    judge_val: res.judge_val,
                    pass_val: res.pass_val,
                    duration: duration, // 这是考试规定的时间
                    duration_passed: res.duration, // 这是正在进行的考试记录已用时间
                    //begin_time: res.begin_time, // 获取本条考试记录开始的时间
                    begin_time: Date.now(),
                });
                this.initTesterAnswers();
            })
            .catch((error)=>{
                console.error('Error:', error)
        })
    }

    componentDidUpdate() {
        if (!this.props.access) this.props.history.push('/login');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    initTesterAnswers = () => {
        const topics = this.state.topics;
        const OPTIONS = ['A','B','C','D','E','F'];
        var _answers = [];
        //const id = this.state.id;
        topics.forEach((topic)=>{
            let single_answer = [];
            if (typeof(topic.tester_answer) !== 'undefined' && topic.tester_answer !== '') {
                [...topic.tester_answer].forEach((aa)=>{
                    single_answer.push(OPTIONS.indexOf(aa));
                });
                _answers.push({'id': topic.id, 'answer': single_answer})
            }
        });
        this.setState({
            'answers': _answers,
        });
    }

    fetchScore = (record_id, score, is_pass, is_finished) => {
        let myHeaders = new Headers();
        moment.locale('zh-cn');
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        myHeaders.append('Content-Type', 'application/json');
        const http_options = {
            method: 'PUT',  // it's hard to achieve this
            body: JSON.stringify({
                score: score,
                is_pass: is_pass,
                is_finished: is_finished,
                end_time: moment().format('YYYY-MM-DDTHH:mm:ss'),
            }),
            headers: myHeaders,
        }
        fetch(BACK_URL+'exam_record_update/'+record_id+'/', http_options)
          .then(res=>res.json())
          .catch(err=>console.log(err))
    }

    submitClicked = () => {
        const answers = this.state.answers;
        const topics = this.state.topics;
        const single_choice_val = this.state.single_choice_val;
        const multiple_choice_val = this.state.multiple_choice_val;
        const judge_val = this.state.judge_val;
        const pass_val = this.state.pass_val;
        let score = 0;
        const options = ['A','B','C','D','E','F'];
        const record_id = this.state.record_id;
        answers.forEach((answer)=>{
            let _answer = '';
            answer.answer.sort().forEach((an)=> {
                _answer = _answer + options[an];
            });
            const topic_answer = topics[answer.id-1].answer;
            if (_answer === topic_answer) {
                const _type = topics[answer.id-1]._type;
                switch(_type) {
                    case '单选'.valueOf():
                        score = score + single_choice_val;
                        break;
                    case '判断'.valueOf():
                        score = score + judge_val;
                        break;
                    default:
                        score = score + multiple_choice_val;
                }
            }
        });
        if (score >= pass_val) {
            alert('你的得分是 ' + score.toString() + '， 恭喜你通过考试。');
            this.fetchScore(record_id, score, true, true);
        } else {
            alert('你的得分是 ' + score.toString() + '， 你没有通过考试。');
            this.fetchScore(record_id, score, false, true);
        }
        this.props.history.push('/userinfo');
    }

    // 题目改变后，定位到当前题目的答案
    getCurrentAnswer = (id) => {
        const answer = this.state.answers.filter(obj=>{
            return obj.id === id;
        });
        if (answer.length > 0){
            return answer[0].answer;
        } else {
            return []
        }
    }

    nextClicked = () => {
        if (this.state.id < this.state.topics.length) {
            this.setState(prevState =>{
                return {
                    id:prevState.id + 1, 
                    answer: this.getCurrentAnswer(prevState.id + 1)
                }
            })
        } else {
            this.submitClicked();
        }
        // 截屏发送
        if (this.state.in_judge && (this.state.id % CAPTURE_FREQUENCY === 0)) {
            document.getElementById('btnCapture').click();
        }
    }

    prevClicked = () => {
        if (this.state.id>1) {
            this.setState(prevState =>{
                return {id:prevState.id-1, answer:this.getCurrentAnswer(prevState.id-1)}
            })
        }
    }

    radioSelected = (evt) => {
        this.setState({answer: [parseInt(evt.target.id)],});
        this.fetchAnswerChange(); // 提交改变到服务器
    }

    // 用户答题后将答案上传至服务器
    fetchAnswerChange = () => {
        const length = this.state.topics.length;
        const answers = this.state.answers;
        const now = Date.now();
        //const begin_time = new Date(this.state.begin_time);
        const begin_time = this.state.begin_time;
        const options = ['A','B','C','D','E','F'];
        const id = this.state.id; // current topic
        const record_id = this.state.record_id; // current exam record id
        //console.log(now);
        //console.log(begin_time);
        //console.log(this.state.duration_passed);
        //console.log(now - begin_time + this.state.duration_passed);
        let answers_to_be_fetch = new Array(length);
        for (let i=0; i<length; i++) { answers_to_be_fetch[i] = ''};
        answers.forEach((answer)=>{
            let _answer = '';
            answer.answer.forEach((a)=>{
                _answer = _answer + options[parseInt(a)];
                //console.log(_answer);
            });
            answers_to_be_fetch[answer.id-1] = _answer;
        });
        if (answers_to_be_fetch.join(',').includes('undefined')) {
            return;
        }
        //console.log(answers_to_be_fetch);
        let myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        myHeaders.append('Content-Type', 'application/json');
        const http_options = {
            method: 'PUT',  // it's hard to achieve this
            body: JSON.stringify({
                answers: answers_to_be_fetch.join(','),
                current_topic: id,
                duration: now - begin_time + this.state.duration_passed,
            }),
            headers: myHeaders,
        }
        fetch(BACK_URL+'exam_record_update/'+record_id+'/', http_options)
          .then(res=>res.json())
          .catch(error=>console.log(error))
    }

    optionClicked = (evt) => {
        const topic_id = this.state.id;
        const topics = this.state.topics;
        const topic = topics[topic_id-1]
        var answers = this.state.answers;
        const option_index = evt.target.id;
        var _answer = answers.filter(obj=>{
            return obj.id === topic_id;
        });
        if (_answer.length === 0) {
            const answers_len = answers.push({id:topic_id, answer:[]});
            _answer = answers[answers_len-1];
        } else {
            _answer = _answer[0];
        }
        if (topic._type === '多选') {
            if (evt.target.checked) {
                if (_answer.answer.indexOf(parseInt(option_index))===-1) {
                    _answer.answer.push(parseInt(option_index));
                }
            } else {
                const index = _answer.answer.indexOf(parseInt(option_index));
                _answer.answer.splice(index,1);
            }
        } else {
            //console.log(option_index);
            _answer.answer = [parseInt(option_index)]
        }
        this.setState({answers: answers});
        this.fetchAnswerChange(); // 提交改变到服务器
    }

    Completionist = () => <h3 style={{margin:"auto"}}>You are good to go!</h3>

    render() {
        const topics = this.state.topics;
        const id = this.state.id;
        const topic = topics[id-1];
        let duration = 1;
        if (typeof(this.state.duration) !== 'undefined') duration=this.state.duration;
        const _duration = this.state._now + duration*60*1000 - this.state.duration_passed; // 减去已用时间
        const answer = this.getCurrentAnswer(id);
        var results = [];
        var Options = [];
        const name = this.props.name;
        let role = this.props.roles;
        const company = this.props.company;
        const in_judge = this.state.in_judge; // 是否在考试
        const judge_tips = '当前是考试模式，你最多可以考3次。考试模式下请确保开启并调整摄像头，截屏图像将用于确认考生身份及打印考核合格证书。考试模式下未提交试卷请勿关闭页面，否则可能导致结果无效。';
        const excercise_tips = '当前是模拟考试模式，系统不会截取摄像头图像，但请确保正式考试后开启摄像头以用于确认考生身份。'
        //console.log('current topic:', topic);
        //if ('_type' in topic) {
            if (topic?._type === '多选') {
                topic.options.forEach((option,index)=>{
                    Options.push(
                        <FormControlLabel 
                            control={<Checkbox 
                                id={index.toString()} 
                                onClick={this.optionClicked} 
                                checked={answer.includes(index)}
                            />} 
                            label={option} 
                            key={option}
                        />
                    )
                });
            } else {
                topic?.options.forEach((option,index)=>{
                    Options.push(
                        <FormControlLabel 
                          value={option.toString()} 
                          control={<Radio id={index.toString()} checked={answer.includes(index)}/>} 
                          label={option}
                          key={option}
                          onClick={this.optionClicked}
                        />
                    )
                });
            }
        //}
        //if ('_type' in topic) {
            if (topic?._type === '多选') {
                results.push(<FormGroup key={1}>{Options}</FormGroup>);
            } else {
                results.push(
                    <FormControl 
                        key={1} 
                        component="fieldset"
                    >
                        <RadioGroup
                            onClick={this.radioSelected}
                        >
                            {Options}
                        </RadioGroup>
                    </FormControl>
                );
            }
        //}
        var nextBtnLabel = '下一题';
        var nextBtnColor = 'primary';
        if (id===topics.length) {
            nextBtnLabel = '交卷';
            nextBtnColor = 'secondary';
        }
        const renderer = ({ hours, minutes, seconds, completed }) => {
            if (completed) {
              // Render a completed state
              return <this.Completionist />;
            } else {
              // Render a countdown
              return <h3 style={{margin:"auto"}}>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</h3>;
            }
          };
          // 注意：此处假设考试中只有一种企业类型
          const company_type = role.split('-')[0] + '-';
          role = role.slice(0, 1) + role.slice(1).replaceAll(company_type, '');
        return (
            <div style={{width:'90%', margin:'auto'}}>
            <Grid container>
                <Grid item xs={12} sm={12} md={5}>
                    <Paper variant="outlined" style={{padding:5}}>
                        <Grid container spacing={1}>
                            <Grid item>
                            <Box><WebcamCapture record_id={this.state.record_id} access={this.props.access}/></Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={7}>
                    <Paper variant="outlined" style={{marginLeft:10,marginRight:10,padding:10,height:235}}>
                        <Typography variant="overline" display="block" gutterBottom>
                            姓名
                        </Typography>
                        <Typography variant="h6" display="block" gutterBottom>
                            { name }
                        </Typography>
                        <Typography variant="overline" display="block" gutterBottom>
                            职务
                        </Typography>
                        <Typography variant="h6" display="block" gutterBottom>
                            { role }
                        </Typography>
                        <Typography variant="overline" display="block" gutterBottom>
                            单位
                        </Typography>
                        <Typography variant="h6" display="block" gutterBottom>
                            { company }
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Alert severity='info' fullWidth variant="outlined" style={{'marginTop':'5px'}}>
                      <AlertTitle>请注意</AlertTitle>
                      {in_judge ? judge_tips : excercise_tips}
                    </Alert>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Box style={{height:30, padding:10, marginLeft:10, marginRight:10, marginTop:5, backgroundColor:"blue", color:"white", textAlign:"center"}}>
                        <Countdown date={_duration} renderer={renderer} onComplete={this.submitClicked} />
                    </Box>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Paper style={{marginTop:10,marginLeft:10,marginRight:10,padding:10}} square>
                        {topics?
                            <div>
                                <Typography variant="body1" display="block" gutterBottom>
                                    { id + '.' }
                                    <span style={{padding:2, marginLeft:5, marginRight:5, color:'red', border:'solid', borderWidth:1}}>
                                        {topic?._type}
                                    </span>
                                    { topic?.desc }
                                </Typography>
                                <div style={{paddingLeft:10}}>
                                    {results}
                                </div>
                                <div style={{textAlign:'center'}}>
                                <Button 
                                    style={{marginRight:5}} 
                                    variant="contained" 
                                    color="primary"
                                    onClick={this.prevClicked}
                                >
                                    上一题
                                </Button>
                                <Button 
                                    style={{marginLeft:5}} 
                                    variant="contained" 
                                    color={nextBtnColor}
                                    onClick={this.nextClicked}
                                >
                                    { nextBtnLabel }
                                </Button>
                                </div>
                            </div>
                        :
                            <p>正在加载试题数据，请稍等。</p>
                        }
                    </Paper>
                </Grid>
            </Grid>
            </div>
        )
    }
}

export default withRouter(ExamPage);