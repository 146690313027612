import React from 'react';
import { Component } from 'react';
import { AppBar, Toolbar, Typography, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

class Header extends Component { 

    onLoginClick = () => {
        this.props.history.push('/login');
    }

    onRegisterClick = () => {
        this.props.history.push('/register')
    }

    onUserinfoClick = () => {
        this.props.history.push('/userinfo');
    }

    onRoleClick = () => {
        this.props.history.push('/userrole');
    }

    render() {
        //const name = this.props.name;
        //const is_staff = this.props.is_staff;
        let welcomeMsg = '';
        if (!this.props.name) {
            welcomeMsg = <span>
                <Button 
                    color="inherit" 
                    onClick={this.onLoginClick}
                >
                    登录
                </Button>
                <Button 
                    color="inherit" 
                    onClick={this.onRegisterClick}
                >
                    注册
                </Button>
            </span>
        } else {
            welcomeMsg = <span>
                <Button 
                    color="inherit" 
                    onClick={
                        ()=>this.props.is_staff?this.props.history.push('/adminhome'):this.props.history.push('/userhome')}
                    >主页
                </Button>
                {this.props.is_staff?
                  '' : 
                  <span>
                      <Button color="inherit" onClick={this.onRoleClick}>我的职务</Button>
                      <Button color="inherit" onClick={this.onUserinfoClick}>我的记录</Button>
                  </span>
                }
                <span>
                    <Button 
                         color="inherit"
                        >欢迎您，{this.props.name}
                    </Button>
                    <Button color="inherit" onClick={this.props.onLogout}>注销</Button>
                </span>
            </span>
        }
                
        return (
            <AppBar position="static" style={{marginBottom:10}}>
                <Toolbar>
                    <Typography ariant="h5" color="inherit" style={{ flex: 1 }}>
                        GMP知识学习小站
                    </Typography>
                    { welcomeMsg }
                </Toolbar>
            </AppBar>
        )
    }
    
}

export default withRouter(Header);