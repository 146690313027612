import React, { Component } from 'react';
import { TextField, Button, Grid, Alert, AlertTitle, Paper, } from '@material-ui/core';
import { BACK_URL } from './Utils';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

const styles = {
    Paper: {
        padding: '10px',
        width: '90%',
        margin:'auto',
    }
}

class RequestResetPasswd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            return_status: '',
            email: '',
            success: null,
        }
    }

    componentDidMount() {
        loadCaptchaEnginge(4); 
    }

    validateEmail = (email) =>
    {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    onSubmit = () => {
        let user_captcha_value = document.getElementById('captcha').value;
        if (validateCaptcha(user_captcha_value)===false) {
            alert('校验码不匹配！');
            return;
        }
        const email = this.state.email;
        if (!this.validateEmail(email)) {
            alert('请检查您的输入是否正确！');
            return;
        }
        const options = {
            method: 'POST',
            body: JSON.stringify({email:email}),
            headers: {
                'Content-Type': 'application/json'
            }
        };
        this.setState({
            'success': null,
            'return_status': '服务器正在处理您的请求...若时间过长，请联系管理员'
        })
        fetch(BACK_URL + 'request_reset_email/', options)
          .then(res=>res.json())
          .then(res=>{
              //console.log(res);
              if ('error' in res) {
                  this.setState({
                      'success': false,
                      'return_status': res.error,
                  }); 
              } else {
                  this.setState({
                      'success': true,
                      'return_status': res.success,
                  })
              }
          })
    }

    onInputChange = (evt) => {
        const id = evt.target.id;
        this.setState({[id]: evt.target.value.trim(),});
    }

    render() {
        const success = this.state.success;
        const return_status = this.state.return_status;
        var result = null;
        if (success === true) {
            result =
                <Alert severity='info' fullWidth variant='outlined'>
                    <AlertTitle>成功</AlertTitle>
                    { return_status }
                </Alert>
        } else if (success === false) {
            result =
                <Alert severity='error' fullWidth variant='outlined'>
                    <AlertTitle>错误</AlertTitle>
                    { return_status }
                </Alert>
        } 
        if (success === null && return_status !== '') {
                result = <Alert severity='error' fullWidth variant='outlined'>
                    <AlertTitle>稍等</AlertTitle>
                    { return_status }
                </Alert>
        }
        return (
            <Paper variant='outlined' style={styles.Paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField 
                          fullWidth
                          required
                          id="email"
                          type="email"
                          label="请输入您注册帐号使用的邮箱"
                          onChange={this.onInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadCanvasTemplateNoReload />
                            <TextField
                            required
                            id="captcha"
                            label="请输入图片中的字母和数字"
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={this.onSubmit}>
                            提交
                        </Button>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>{result}</Grid>
                </Grid>
            </Paper>
        )
    }
}

export default RequestResetPasswd;