import React, { Component } from 'react';
import { 
  TextField, 
  Button, 
  Paper, Grid 
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

const styles = {
    Paper: {
        margin: 'auto',
        padding: 10,
        width: '90%',
    },
    Button: {
        margin: 10,
    },
    hint: {
      margin: 'auto',
      display: 'flex',
      padding: 10,
      width: '80%',
    }
}

class Login extends Component {

  componentDidUpdate() {
    if (this.props.access) {
      if (this.props.is_staff) {
        this.props.history.push('/adminhome')
      } else {
          this.props.history.push('/userhome');
      }
    }
    loadCaptchaEnginge(4); 
  }

  onLoginClicked = () => {
    let user_captcha_value = document.getElementById('captcha').value;
    if (validateCaptcha(user_captcha_value)===true) {
      this.props.onLoginClicked();
      if (this.props.access) this.props.history.push('/home');
    } else {
      alert('校验码不匹配！');
    }
  }

  forgetUsername = () => {
    this.props.history.push('/getusername');
  }

  resetPasswd = () => {
    this.props.history.push('/request_reset_passwd');
  }

  render() {
        return(
          <div>
            <Paper variant="outlined" style={styles.Paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="username"
                      label="用户名"
                      defaultValue=""
                      onChange={this.props.onLoginInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="password"
                      label="密码"
                      type="password"
                      onChange={this.props.onLoginInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LoadCanvasTemplateNoReload />
                    <TextField
                      required
                      id="captcha"
                      label="请输入图片中的字母和数字"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button 
                      style={styles.Button}
                      variant="contained"
                    >复位</Button>
                    <Button 
                      style={styles.Button}
                      variant="contained"
                      color="primary"
                      onClick={this.onLoginClicked}
                    >登录</Button>
                    <Button 
                      style={styles.Button}
                      variant="contained"
                      color="secondary"
                      onClick={this.forgetUsername}
                    >找回用户名</Button>
                    <Button 
                      style={styles.Button}
                      variant="contained"
                      color="secondary"
                      onClick={this.resetPasswd}
                    >找回密码</Button>
                  </Grid>
                </Grid>    
                <Grid item xs={12}>
                </Grid>
            </Paper>
            <div style={styles.hint}>
              <p style={{'color':'red'}}>
                注意：<br/>1. 本系统建议使用<b>火狐浏览器</b>或<b>谷歌浏览器</b>访问。<br/>2. 进入考试页面需<b>开启浏览器访问摄像头权限</b>，否则考试结果可能无效。
              </p>
            </div>
          </div>
        )
    }
}

export default withRouter(Login);