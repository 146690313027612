import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { BACK_URL, download_table_as_csv } from './Utils';
import {
    Paper, Grid, Typography, Table,
    TableBody, TableCell, TableContainer, 
    TableHead, TableRow, TableFooter, Button
} from '@material-ui/core';

const styles = {
    Paper: {
        margin: 'auto',
        display: 'flex',
        padding: '10px',
        width: '90%',
    },
}

class Statistics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            results: [],
        }
    }

    componentDidUpdate() {
        if (this.props.access === '') this.props.history.push('/login');
    }

    componentDidMount() {
        var myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer '+this.props.access);
        fetch(BACK_URL+'get_statistics',{headers:myHeaders})
          .then(res=>{
              if (res.ok) {
                  return res.json()
              } else {
                  alert('Error');
              }
          }).then(res=>{
              this.setState({results:res,})
          }).catch(err=>console.log(err));
    }

    render() {
        const results = this.state.results;
        let exams = [];
        //console.log(results);
        if (results !== undefined && results.length > 0) exams = results[0].data;
        var heads = [];
        exams.forEach(exam => {
            heads.push(
                <TableCell>{exam.ename}</TableCell>
            )
        });
        var data = [];
        results !== undefined && results.forEach((r,index)=>{
            const data1 = r.data;
            var enrolls = [];
            data1.forEach((d)=>{
                enrolls.push(<TableCell>{d.enroll_pass}</TableCell>)
            });
            data.push(
                <TableRow>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>{r.cname}</TableCell>
                    {enrolls}
                </TableRow>
            );
        });
        return (
            <Paper variant='outlined' style={styles.Paper}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant='h5' style={{'margin':'5px', 'align': 'center'}}>
                            六分局辖区药品生产企业参加考核情况统计表（通过人数/报名人数）
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TableContainer component={Paper}>
                            <Table id="table1">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>序号</TableCell>
                                        <TableCell>企业名称</TableCell>
                                        { heads }
                                    </TableRow>
                                </TableHead>
                                <TableBody>{data}</TableBody>
                                <TableFooter>
                                    <Button onClick={()=>download_table_as_csv('table1')}>导出</Button>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withRouter(Statistics);