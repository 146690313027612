import React, { Component } from 'react';
import dateFormat from 'dateformat';
import { withRouter } from 'react-router-dom';
import { BACK_URL, exportJSONToCSV, download_table_as_csv } from './Utils';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import {
    Paper, Table,
    TableBody, TableCell, TableContainer, 
    TableHead, TableRow, Grid, TableFooter,
    TextField, Button, Typography
} from '@material-ui/core';

const styles = {
    Paper: {
        margin: 'auto',
        display: 'flex',
        padding: '10px',
        width: '90%',
    },
}

class EnrollSearch extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            enrolls: [],
            company: '',
            name: '',
        }
    }

    componentDidUpdate() {
        if (this.props.access === '') this.props.history.push('/login');
        loadCaptchaEnginge(4); 
    }

    onInputChange = (evt) => {
        const id = evt.target.id;
        this.setState({[id]: evt.target.value.trim(),});
    }

    onViewRecord = (tester_id) => {
        //console.log(tester_id);
        this.props.history.push(
            {
                pathname: '/exam_records',
                state: {
                    examId: this.props.location.state.exam_id,
                    testerId: tester_id,
                }
            }
        )
    }

    onQueryClick = () => {
        let user_captcha_value = document.getElementById('captcha').value;
        if (validateCaptcha(user_captcha_value)===false) {
            alert('校验码不匹配！');
            return;
        }
        this.setState({enrolls:[]});
        const company = this.state.company;
        const name = this.state.name;
        if (company.trim().length < 2) return;
        var myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer '+this.props.access);
        fetch(BACK_URL+'exam_enrolls/?company='+company+'&name='+name+'&exam_id='+this.props.location.state.exam_id, {headers:myHeaders})
          .then(res=>{
              if (res.ok) {
                  return res.json()
              } else {
                  alert('Error');
              }
          }).then(res=>{
              this.setState({enrolls:res,})
          }).catch(err=>console.log(err));
    }

    onExportClick = () => {
        if (window.confirm('你要导出考试的全部成绩吗？这个操作可能需要几分钟的时间。')) {
            var myHeaders = new Headers();
            myHeaders.append('Authorization','Bearer '+this.props.access);
            fetch(BACK_URL+'exam_enrolls/?company=all&&exam_id='+this.props.location.state.exam_id, {headers:myHeaders})
            .then(res=>{
                if (res.ok) {
                    return res.json()
                } else {
                    alert('Error');
                }
            }).then(res=>{
                //this.setState({enrolls:res,})
                exportJSONToCSV(res);
            }).catch(err=>console.log(err));

            }
    }

    render() {
        const exam_name = this.props.location.state.name;
        const enrolls = this.state.enrolls;
        var results = [];
        if (enrolls !== undefined) {
            enrolls.forEach(enroll=>{
                results.push(
                    <TableRow key={enroll.id}>
                        <TableCell><Button variant='text' onClick={()=>this.onViewRecord(enroll.tester)}>{enroll.tester_name}</Button></TableCell>
                        <TableCell>{enroll.tester_company}</TableCell>
                        <TableCell>{enroll.tel}</TableCell>
                        <TableCell>{enroll.tester_role}</TableCell>
                        <TableCell>{dateFormat(enroll.ddate,"yyyy-mm-dd H:MM")}</TableCell>
                        <TableCell>{enroll.mock_cnt}</TableCell>
                        <TableCell>{enroll.mock_pass_radio}</TableCell>
                        <TableCell>{enroll.exam_score}</TableCell>
                        <TableCell>{enroll.is_passed?'Y':'N'}</TableCell>
                    </TableRow>
                )
            })
        }
        const head = (
            <TableRow>
                <TableCell>姓名</TableCell>
                <TableCell>所属单位</TableCell>
                <TableCell>电话</TableCell>
                <TableCell>职务</TableCell>
                <TableCell>报名日期</TableCell>
                <TableCell>练习次数</TableCell>
                <TableCell>练习通过率</TableCell>
                <TableCell>考试成绩</TableCell>
                <TableCell>是否通过</TableCell>
            </TableRow>
        );
        return (
                <Paper variant='outlined' style={styles.Paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h5' style={{'margin':'5px'}}>
                                { exam_name }
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth
                              id="company"
                              label="企业名称"
                              onChange={this.onInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth
                              id="name"
                              label="姓名"
                              onChange={this.onInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadCanvasTemplateNoReload />
                            <TextField
                            required
                            id="captcha"
                            label="请输入图片中的字母和数字"
                        />
                  </Grid>
                        <Grid item xs={12}>
                            <Button
                              id="btn_query"
                              label="查询"
                              onClick={this.onQueryClick}
                              variant="contained"
                            >查询</Button>
                            <Button
                              id="btn_query2"
                              label="导出全部"
                              onClick={this.onExportClick}
                              variant="contained"
                              style={{'marginLeft':'5px',}}
                            >导出全部</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table id="table1">
                                    <TableHead>
                                        { results.length>0 ? head : ''}
                                    </TableHead>
                                    <TableBody>
                                        { results }
                                    </TableBody>
                                    <TableFooter>
                                        { results.length>0? <Button onClick={()=>download_table_as_csv('table1')}>导出</Button>:''}
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Paper>
        )
    }
}

export default withRouter(EnrollSearch);