import React, { Component } from 'react';
import dateFormat from 'dateformat';
import { withRouter } from 'react-router-dom';
import { BACK_URL } from './Utils';
import {
    Card, CardContent, Typography, Paper,
    TableBody, TableCell, TableContainer, 
    TableHead, TableRow,
} from '@material-ui/core';

class Enrolled extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enrolls: [],
        }
    }

    componentDidMount() {
        if (this.props.access==='') this.props.history.push('/login');
        const exam_id = this.props.location.state.exam_id;
        var myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        fetch(BACK_URL + 'exam_enrolls/?exam_id='+exam_id, {headers: myHeaders})
            .then(res=>{ if (res.ok) {return res.json()} else {
                this.props.history.push('login');
            }})
            .then(res =>{
                this.setState({enrolls:res,});
            })
            .catch(err=>{
                console.log(err);
            });
    }

    componentDidUpdate() {
        if (!this.props.access) this.props.history.push('/login');
    }

    viewExamRecord = (id) => {
        this.props.history.push({
            pathname: '/exam_records',
            state: {examId: id,}
        })
    }

    render() {
        const enrolls = this.state.enrolls;
        let results = [];
        let title = '';
        if (enrolls !== undefined && enrolls.length > 0) {
            title = enrolls[0].exam_name;
            enrolls.forEach(enroll=>{
                results.push(
                    <TableRow key={enroll.id}>
                        <TableCell>{enroll.tester_name}</TableCell>
                        <TableCell>{enroll.tester_company}</TableCell>
                        <TableCell>{enroll.tester_role}</TableCell>
                        <TableCell>{dateFormat(enroll.ddate,"yyyy-mm-dd H:MM")}</TableCell>
                        <TableCell>{enroll.mock_cnt}</TableCell>
                        <TableCell>{enroll.mock_pass_radio}</TableCell>
                        <TableCell>{enroll.exam_score}</TableCell>
                        <TableCell>{enroll.is_passed?'Y':'N'}</TableCell>
                    </TableRow>
                )
            })
        }
        return (
            <Card variant="outlined" style={{width:'80%', margin:'auto'}}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {title + ' 报名情况'}
                    </Typography>
                    <TableContainer component={Paper}>
                        <TableHead>
                            <TableRow>
                                <TableCell>姓名</TableCell>
                                <TableCell>所属单位</TableCell>
                                <TableCell>职务</TableCell>
                                <TableCell>报名日期</TableCell>
                                <TableCell>练习次数</TableCell>
                                <TableCell>练习通过率</TableCell>
                                <TableCell>考试成绩</TableCell>
                                <TableCell>是否通过</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { results }
                        </TableBody>
                    </TableContainer>
                </CardContent>
            </Card>
        )
    }
}


export default withRouter(Enrolled);