import React, { Component } from 'react';
import { TextField, Button, Grid, Paper } from '@material-ui/core';
import { withRouter } from "react-router";
import { BACK_URL } from './Utils';

const styles = {
    Paper: {
        padding: '10px',
        width: '90%',
        margin:'auto',
    },
    TextField: {
        margin: '5px',
    }
}

class ResetPasswd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            passwd1: '',
            passwd2: '',
        }
    }

    validatePass = (passwd) =>
    {
        return passwd.strip().length >= 8;
    }

    onSubmit = () => {
        const passwd1 = this.state.passwd1;
        const passwd2 = this.state.passwd2;
        if (passwd1.length < 8 || passwd2.length < 8) {
            alert('密码必须大于8位。')
            return;
        }
        if (passwd1.trim() !== passwd2.trim()) {
            alert('两次输入的密码必须相同。');
            return;
        }
        const search = this.props.location.search;
        const uidb64 = new URLSearchParams(search).get("uidb64");
        const token = new URLSearchParams(search).get("token");
        const options = {
            method: 'PATCH',
            body: JSON.stringify({
                passwd:passwd1,
                uidb64:uidb64,
                token:token
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        };
        fetch(BACK_URL + 'passwd_reset_complete/', options)
          .then(res=>res.json())
          .then(res=>{
              console.log(res);
              if ('success' in res) {
                  alert('密码重设成功，请登录。');
                  this.props.history.push('/login');
              } else {
                alert('密码重设出现错误。');
                this.props.history.push('/login');
              }
          })
    }

    onInputChange = (evt) => {
        const id = evt.target.id;
        this.setState({[id]: evt.target.value.trim(),});
    }

    render() {
        return (
            <Paper variant='outlined' style={styles.Paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField 
                          fullWidth
                          required
                          id="passwd1"
                          type="password"
                          label="请输入您的新密码（不少于8位）"
                          onChange={this.onInputChange}
                          style={styles.TextField}
                        />
                        <TextField 
                          fullWidth
                          required
                          id="passwd2"
                          type="password"
                          label="请再次输入您的新密码（不少于8位）"
                          onChange={this.onInputChange}
                          style={styles.TextField}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={this.onSubmit}>
                            提交
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        )
    }
}

export default withRouter(ResetPasswd);