import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Paper, TextField, Button, Typography, 
    Autocomplete, FormControl, InputLabel, 
    Select, MenuItem } from '@material-ui/core';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import moment from 'moment';
import { BACK_URL } from "./Utils";

const styles = {
    TextField: {
        margin: 5,
    },
    Button: {
        margin: 10,
    },
    h4: {
        margin: 20,
    },
    Paper: {
        padding: 5,
    }
}

class ManageForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _type: '', // form type
            role: {}, // to be post
            roles: [], // roles exist
            pools: {}, // question bank
            exam: {},
            exam_mock_begin_date: null,
            exam_mock_end_date: null,
            exam_begin_date: null,
            exam_end_date: null,
            selectedRole: [],
            selectedPool: '',
        }
    }

    componentDidMount() {
        const _type = this.props.location.state._type;
        const _id = this.props.location.state.id;
        console.log(_type,_id);
        if (_type === 'role') {
            this.fetch_roles();
        } else if (_type === 'exam') {
            this.fetch_roles();
            this.fetch_pools();
        }
        //this.setDate();
    }

    setDate = () => {
        const dateObj1 = new Date();
        const dateObj2 = new Date();
        dateObj2.setDate(dateObj2.getDate() + 1);
        const today = dateObj1.getFullYear()  + '-'+ dateObj1.getMonth()  + '-' + String(dateObj1.getDate()).padStart(2, '0');
        const tomorrow = dateObj2.getFullYear()  + '-'+ dateObj2.getMonth()  + '-' + String(dateObj2.getDate()).padStart(2, '0');
        this.setState({
            exam_mock_begin_date: today,
            exam_mock_end_date: today,
            exam_begin_date: tomorrow,
            exam_end_date: tomorrow,
        });
    }

    componentDidUpdate() {
        if (!this.props.access) this.props.history.push('/login');
    }

    fetch_roles = () => {
        var myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        fetch(BACK_URL + 'role_list/', {headers: myHeaders})
            .then(res=>{ if (res.ok) {
                return res.json()
            } else {
                this.props.history.push('/login');
            }})
            .then(res =>{
                this.setState({roles:res.results,});
            })
            .catch(err=>{
                console.log(err);
            })
    }

    fetch_pools = () => {
        var myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        fetch(BACK_URL + 'question_bank/', {headers: myHeaders})
            .then(res=>{ if (res.ok) {
                return res.json()
            } else {
                this.props.history.push('/login');
            }})
            .then(res =>{
                this.setState({pools: res.results,});
            })
            .catch(err=>{
                console.log(err);
            })
    }

    postRole = () => {
        const company_type = document.getElementById('company_type').value;
        const name = document.getElementById('name').value;
        if (company_type !== '' && name !== '') {
            var myHeaders = new Headers();
            myHeaders.append('Authorization','Bearer ' + this.props.access);
            myHeaders.append('Content-Type','application/json;charset=UTF-8');
            const options = {
                method: "POST",
                body: JSON.stringify({
                    'company_type': company_type,
                    'name': name,
                }),
                headers: myHeaders,
            };
            fetch(BACK_URL+'role_create/', options)
                .then(res=>res.json())
                .then(res=>{
                    if ('name' in res) {
                        this.props.history.push('/adminhome');
                    } else {
                        alert('创建角色出现错误。')
                    }
                });
        } else alert('请检查你的输入。');
    }

    postCompany = () => {
        const company_name = document.getElementById('company2_name').value;
        const company_cred = document.getElementById('company2_cred').value;
        const company_type = document.getElementById('company2_type').value;
        if (company_cred !== '' && company_name !== '' && company_type !== '') {
            var myHeaders = new Headers();
            myHeaders.append('Authorization','Bearer ' + this.props.access);
            myHeaders.append('Content-Type','application/json;charset=UTF-8');
            const options = {
                method: "POST",
                body: JSON.stringify({
                    '_type': company_type,
                    'name': company_name,
                    'cred_no': company_cred,
                }),
                headers: myHeaders,
            };
            fetch(BACK_URL+'company_create/', options)
                .then(res=>res.json())
                .then(res=>{
                    if ('name' in res) {
                        this.props.history.push('/adminhome');
                    } else {
                        alert('创建企业出现错误。')
                    }
                });
        }  else alert('请检查你的输入。');
    }

    postPool = () => {
        const pool_name = document.getElementById('pool_name').value;
        var myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        myHeaders.append('Content-Type','application/json;charset=UTF-8');
        const options = {
            method: "POST",
            body: JSON.stringify({
                'name': pool_name,
                'creater': this.props.name,
            }),
            headers: myHeaders,
        };
        fetch(BACK_URL+'question_bank/', options)
            .then(res=>res.json())
            .then(res=>{
                if ('name' in res) {
                    this.props.history.push('/adminhome');
                } else {
                    alert('创建题库出现错误。');
                }
            })
    } 
    
    postExam = () => {
        const exam_name = document.getElementById('exam_name').value;
        const exam_pool = this.state.selectedPool;
        const exam_roles = this.state.selectedRole;
        let roles = [];
        exam_roles.forEach(r=>roles.push(''+r));
        const exam_mock_begin_date = moment(this.state.exam_mock_begin_date).format('YYYY-MM-DD 00:00:01');
        const exam_mock_end_date = moment(this.state.exam_mock_end_date).format('YYYY-MM-DD 23:59:59');
        const exam_begin_date = moment(this.state.exam_begin_date).format('YYYY-MM-DD 00:00:01');
        const exam_end_date = moment(this.state.exam_end_date).format('YYYY-MM-DD 23:59:59');
        const exam_single_num = document.getElementById('exam_single_num').value;
        const exam_single_val = document.getElementById('exam_single_val').value;
        const exam_mul_num = document.getElementById('exam_mul_num').value;
        const exam_mul_val = document.getElementById('exam_mul_val').value;
        const exam_check_num = document.getElementById('exam_check_num').value;
        const exam_check_val = document.getElementById('exam_check_val').value;
        const exam_pass_val = document.getElementById('exam_pass_val').value;
        const exam_duration = document.getElementById('exam_duration').value;
        var myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        myHeaders.append('Content-Type','application/json;charset=UTF-8');
        const options = {
            method: "POST",
            body: JSON.stringify({
                'name': exam_name,
                'question_bank': exam_pool,
                'role': roles,
                'mock_exam_begin': exam_mock_begin_date,
                'mock_exam_end': exam_mock_end_date,
                'exam_begin': exam_begin_date,
                'exam_end': exam_end_date,
                'duration': exam_duration,
                'single_choice_num': exam_single_num,
                'single_choice_val': exam_single_val,
                'multiple_choice_num': exam_mul_num,
                'multiple_choice_val': exam_mul_val,
                'judge_num': exam_check_num,
                'judge_val': exam_check_val,
                'pass_val': exam_pass_val,
                'is_open': 1,
                'created': moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                'creater': this.props.name,
            }),
            headers: myHeaders,
        };
        fetch(BACK_URL+'exam_create/', options)
            .then(res=>res.json())
            .then(res=>{
                if ('name' in res) {
                    this.props.history.push('/adminhome');
                } else {
                    alert('创建考试出现错误。');
                }
            })
    }

    handleSelectRoles = (e) => {
        const {
            target: { value },
        } = e;
        this.setState({'selectedRole': typeof value === 'string' ? value.split(',') : value});
    }

    handleSelectPool = (e) => {
        const {
            target: { value },
        } = e;
        this.setState({'selectedPool': value});
    }

    render() {
        let company_type_list = [];
        let role_name = [];
        const roles = this.state.roles;
        if (roles !== undefined && roles.length > 0) {
            roles.forEach(role=>{
                if (company_type_list.indexOf(role.company_type) === -1) company_type_list.push(role.company_type);
                if (role_name.indexOf(role.name) === -1) role_name.push(role.name);
            })
        }
        const RoleForm = <Paper variant="outlined">
            <Typography variant="h4" component="h4" style={styles.h4}>
                请输入角色信息
            </Typography>
            <Autocomplete
                style={styles.TextField} 
                id="company_type"
                freeSolo="true"
                autoSelect
                options={company_type_list.map((name) => name)}
                defaultValue={this.props.location.state.company_type}
                renderInput={
                    (params) => (
                    <TextField
                        fullWidth
                        {...params} 
                        label="企业类型" 
                    />)}
            />
            <Autocomplete
                style={styles.TextField} 
                id="name"
                freeSolo
                autoSelect
                options={role_name.map((r_name) => r_name)}
                defaultValue={this.props.location.state.name}
                renderInput={(params) => 
                    <TextField 
                        fullWidth
                        {...params} 
                        label="角色名称" 
                    />}
            />
            <Button 
                style={styles.Button} 
                variant="contained" 
                color="primary"
                onClick={this.postRole}
            >提交</Button>
        </Paper>
        const pools = this.state.pools;
        let menuItems = [];
        if (pools !== undefined && pools.length > 0) {
            pools.forEach((pool)=>{
                menuItems.push(
                    <MenuItem key={pool.id} value={pool.id}>{pool.name}</MenuItem>
                )
            })
        }
        let roleMenuItems = [];
        if (roles !== undefined && roles.length > 0) {
            roles.forEach((role)=>{
                roleMenuItems.push(
                    <MenuItem key={role.id} value={role.id}>{role.company_type+'-'+role.name}</MenuItem>
                )
            })
        }
        const ExamForm = <Paper variant="outlined" style={styles.Paper}>
            <Typography variant="h4" component="h4" style={styles.h4}>
                请输入考试信息
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="exam-pool-select-label">选择题库</InputLabel>
                <Select
                    labelId="exam-pool-select-label"
                    id="exam_pool"
                    label="选择题库"
                    style={styles.TextField}
                    onChange={this.handleSelectPool}
                    defaultValue={'1'}
                >
                    { menuItems }
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="exam-pool-select-roles">选择参加人员</InputLabel>
                <Select
                    labelId="exam-pool-select-roles"
                    id="exam_roles"
                    label="选择参加人员"
                    style={styles.TextField}
                    multiple
                    defaultValue={'1'}
                    value={this.state.selectedRole}
                    onChange={this.handleSelectRoles}
                >
                    { roleMenuItems }
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <TextField label='考试名称' id='exam_name' style={styles.TextField} />
            </FormControl>
            <FormControl fullWidth>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker 
                            InputProps={{
                                style:{
                                    margin: '8px',
                                }
                            }}
                            label="选择测试开始日期"
                            style={styles.TextField}
                            format="YYYY-MM-DD"
                            value={this.state.exam_mock_begin_date}
                            id="exam_mock_begin_date"
                            onChange={(newValue)=>{
                                this.setState({'exam_mock_begin_date': newValue})
                            }}
                            renderInput={(params)=><TextField {...params}/>}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker 
                            InputProps={{
                                style:{
                                    margin: '8px',
                                }
                            }}
                            label="选择测试结束日期"
                            format="YYYY-MM-DD"
                            value={this.state.exam_mock_end_date}
                            id="exam_mock_end_date"
                            onChange={(newValue)=>{
                                this.setState({'exam_mock_end_date': newValue})
                            }}
                            renderInput={(params)=><TextField {...params}/>}
                        />
                    </LocalizationProvider>
                </div>
            </FormControl>
            <FormControl>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker 
                            label="选择考试开始日期"
                            format="YYYY-MM-DD"
                            InputProps={{
                                style:{
                                    margin: '8px',
                                }
                            }}
                            value={this.state.exam_begin_date}
                            id="exam_begin_date"
                            onChange={(newValue)=>{
                                this.setState({'exam_begin_date':newValue})
                            }}
                            renderInput={(params)=><TextField {...params}/>}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker 
                            label="选择考试结束日期"
                            format="YYYY-MM-DD"
                            InputProps={{
                                style:{
                                    margin: '8px',
                                }
                            }}
                            value={this.state.exam_end_date}
                            id="exam_end_date"
                            onChange={(newValue)=>{
                                this.setState({'exam_end_date': newValue})
                            }}
                            renderInput={(params)=><TextField {...params}/>}
                        />
                    </LocalizationProvider>
                </div>
            </FormControl>
            <FormControl fullWidth>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <TextField id="exam_single_num" label="单选数量" style={styles.TextField} />
                    <TextField id="exam_single_val" label="单选分值" style={styles.TextField} />
                </div>
            </FormControl>
            <FormControl>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <TextField id="exam_mul_num" label="多选数量" style={styles.TextField} />
                    <TextField id="exam_mul_val" label="多选分值" style={styles.TextField}/>
                </div>      
                <div style={{display: 'flex', alignItems: 'center'}}>                         
                    <TextField id="exam_check_num" label="判断数量" style={styles.TextField} />
                    <TextField id="exam_check_val" label="判断分值" style={styles.TextField} />
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <TextField id="exam_duration" label="时长（分钟）" style={styles.TextField}/>
                    <TextField id="exam_pass_val" label="合格分数线" style={styles.TextField} />
                </div>
                <Button 
                    style={styles.Button} 
                    variant="contained" 
                    color="primary"
                    onClick={this.postExam}
                >提交</Button>
            </FormControl>
        </Paper>
        const PoolForm = <Paper variant="outlined">
            <Typography variant="h4" component="h4" style={styles.h4}>
                请输入题库信息
            </Typography>
            <FormControl fullWidth>
                <TextField id="pool_name" label='题库名称' style={styles.TextField} />
                <Button variant="contained" color="primary" style={styles.Button} onClick={this.postPool}>
                    提交
                </Button>
            </FormControl>
        </Paper>
        const CompanyForm = <Paper variant="outlined">
        <Typography variant="h4" component="h4" style={styles.h4}>
            请输入企业信息
        </Typography>
        <FormControl fullWidth>
            <TextField id="company2_cred" label='统一社会信用代码' style={styles.TextField} />
            <TextField id="company2_name" label='企业名称' style={styles.TextField} />
            <TextField id="company2_type" label='企业类型' style={styles.TextField} defaultValue="药品生产企业" />
            <Button variant="contained" color="primary" style={styles.Button} onClick={this.postCompany}>
                提交
            </Button>
        </FormControl>
    </Paper>
        return <div style={{width:'80%', margin:'auto'}}>
            { this.props.location.state.id === 0 ? 
              RoleForm : (
                  this.props.location.state.id === 1 ? PoolForm : (
                      this.props.location.state.id === 2 ? ExamForm : CompanyForm
                    )
                )
            }
        </div>
    }
}

export default withRouter(ManageForm); 