import React from 'react';
import { Divider, Typography } from '@material-ui/core';

const Footer = () => {
    return (
        <div style={{marginTop:10}}>
            <Divider/>
            <Typography 
                variant="overline" 
                display="block" 
                gutterBottom
                align="center"
            >
                对本网站有任何建议，请联系 jnpia667(at)163.com
            </Typography>
        </div>
    )
} 

export default Footer;