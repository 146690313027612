import React, { Component } from 'react';
import { 
  TextField, Button, Paper, Grid, Checkbox, Select, 
  MenuItem, FormControl, InputLabel, OutlinedInput, 
  ListItemText, Autocomplete, Alert, AlertTitle,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { BACK_URL } from './Utils';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

//import SelectInput from '@material-ui/core/Select/SelectInput';


const styles = {
    Paper: {
        margin: 'auto',
        display: 'flex',
        padding: '10px',
        width: '90%',
        height: '100%',
    },
    TextField: {
      
    },
    Button: {
        margin: 10,
    }
}

class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            tel: '',
            password: '',
            password1: '',
            company: '',
            name: '',
            email: '',
            roles: '',
            _roles: [],
            roles_tobe_selected: [],
            company_type_list: [],
            roles_selected: [],
            company_type_selected: '',
            companies: [],
        }
    }

    componentDidMount() {
      loadCaptchaEnginge(4); 
      this.fetch_company_types();
      //this.fetch_companies();
    }

    fetch_company_types = () => {
      fetch(BACK_URL+'role_list/')
        .then(res=>res.json())
        .then(res=>{
          var company_type_list = [];
          res.results.forEach(r=>{
            if (company_type_list.indexOf(r.company_type) === -1) {
              company_type_list.push(r.company_type);
            }
          });
          this.setState({_roles: res.results, company_type_list: company_type_list,})
        })
        .catch(err=>console.log(err));
    }

    checkEmailExist = async (email) => {
      const url = `check_email_exist/${email}/`;
      let emailExists = false;
      try {
        const res = await fetch(BACK_URL + url);
        const data = await res.json();
        console.log(data);
        emailExists = data['email_exists'];
      } catch (err) {
        console.log(err);
      }
      return emailExists;
    };

    fetch_companies = (cname) => {
      fetch(BACK_URL+'companies/?cname='+cname)
        .then(res=>res.json())
        .then(res=>{
          this.setState({companies: res.results,})
        })
        .catch(err=>console.log(err));
    }

    onInputChange = (evt) => {
        const id = evt.target.id;
        //console.log(id);
        this.setState({[id]: evt.target.value.trim(),});
        if (id === 'name') {
            document.cookie = `name=${evt.target.value.trim()};`;
        }
    }

    onCompanyChange = (event,values) => {
      //console.log(values);
      if (values === undefined) return;
      if (values === null) return;
      if (typeof(values.label) === 'undefined') return;
      if (typeof(values.label) === null) return;
      this.setState({company: values.label,});
      if (values.label.length >=2) this.fetch_companies(values.label);
    }

    onRegisterClicked = async () => {
      let user_captcha_value = document.getElementById('captcha').value;
      if (validateCaptcha(user_captcha_value)===false) {
        alert('校验码不匹配！');
        return;
      }
        if (this.state.name.trim() === '') {
            alert('姓名不符合要求');
            return;
        }
        if (this.state.username.trim() === '') {
          alert('用户名不符合要求');
          return;
        }
        if (this.state.tel.trim() === '' || this.state.tel.trim().length !== 11 || !/^\d+$/.test(this.state.tel)) {
            alert('手机号码不符合要求');
            return;
        }
        if (this.state.company.trim() === '') {
            alert('企业名称不符合要求');
            return;
        }
        if (this.state.email.trim() === '') {
            alert('邮箱地址不符合要求');
            return;
        }
        const emailExists = await this.checkEmailExist(this.state.email);
        //console.log(emailExists);
        if (emailExists) {
          alert('邮箱已经被注册，请填写其他邮箱');
          return;
        }
        if (this.state.password !== this.state.password1) {
            alert('两次输入的密码不匹配');
            //console.log(this.state.password + ' vs ' + this.state.password1);
            return;
        }
        if (this.state.password.length < 8) {
            alert('密码不得少于8位');
            return;
        }
        if (this.state.company_type_selected === '') {
          alert('必须选择企业类型。');
          return;
        }
        if (this.state.roles_selected.length === 0) {
          alert('必须选择至少一个角色');
          return;
        }
        if (this.isCompanyAndNameExist > 0) {
          alert(this.state.company + '已经存在用户：'+this.state.name);
          return;
        }
        const options = {
            method: 'POST',
            body: JSON.stringify({
                username: this.state.username,
                password: this.state.password,
                company: this.state.company,
                name: this.state.name,
                //roles: this.state.roles,
                company_type: this.state.company_type_selected,
                roles: this.state.roles_selected.join(','),
                email: this.state.email,
                tel: this.state.tel,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        };
        fetch(BACK_URL+'register/', options)
          .then(res=>{
            if (!res.ok) {
              return res.text().then(text => { throw new Error(text) });
            }
            return res.json();
          })
          .then(res=>{
            document.cookie = 'username=' + res.username + ';';
            alert('您已成功注册，请登录。');
            this.props.history.push('/login');
          })
          .catch((err)=>{
            alert('注册出现错误，'+err.message);
          });
    }

    handleCompanyTypeChange = (evt) => {
      const val = evt.target.value;
      if (val === '') return;
      //if (val.length >=2) this.fetch_companies(val);
      this.setState({company_type_selected: val,});
      const _roles = this.state._roles;
      //const roles = this.state.roles;
      let roles_tobe_selected = [];
      _roles.forEach(r=>{
        if (r.company_type === val) roles_tobe_selected.push(r.name);
      });
      this.setState({roles_tobe_selected: roles_tobe_selected,});
    }

    handleRolesChange = (evt) => {
      let roles_selected = this.state.roles_selected;
      const value = evt.target.id;
      if (roles_selected.indexOf(value) > -1) {
        let new_roles_selected = [];
        roles_selected.forEach(role=>{
          if (role !== value) {
            new_roles_selected.push(role);
          }
        });
        this.setState({roles_selected: new_roles_selected});
      } else {
        roles_selected.push(value);
        this.setState({roles_selected: roles_selected,})
      }
    }

    onInputInCompanyChange = (val) =>{
      //console.log(val);
      if(val.length >=2) {
        this.fetch_companies(val);
      }
    }

    render() {
      const company_type_list = this.state.company_type_list;
      let company_type_selected = '';
      if (this.state.company_type_selected) company_type_selected = this.state.company_type_selected;
      const roles_tobe_selected = this.state.roles_tobe_selected;
      const roles_selected = this.state.roles_selected;
      var companies = [];
      var roles_list = [];
      var company_options = [];

      this.state.companies.forEach(c=>{
        if (c._type === company_type_selected) {
          company_options.push({
            label: c.name,
            id: c.id,
          });
        }
      })

      if (company_type_list.length > 0) {
        company_type_list.forEach(c=>{
          companies.push(
            <MenuItem key={c} value={c}>{c}</MenuItem>
          )
        })
      }

      roles_tobe_selected.forEach(role=>{
        roles_list.push(
          <MenuItem key={role} value={role}>
            <Checkbox id={role} checked={roles_selected.indexOf(role)>-1} onClick={this.handleRolesChange} />
            <ListItemText primary={role} />
          </MenuItem>
        )
      })

      return(
            <Paper variant="outlined" style={styles.Paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Alert severity='info' fullWidth variant="outlined" >
                      <AlertTitle>请注意</AlertTitle>
                      每个企业每名员工只能注册一个账户！
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
  
                    <TextField fullWidth
                      required
                      id="name"
                      label="请输入真实姓名"
                      style={styles.TextField}
                      onChange={this.onInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="company_type_select">企业类型</InputLabel>
                      <Select 
                        labelId="company_type_select"
                        id="c_t_s"
                        label="企业类型"
                        onChange={this.handleCompanyTypeChange}
                        value={company_type_selected}
                      >
                        { companies }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      options={company_options}
                      fullWidth
                      id="company"
                      renderInput={(params)=><TextField {...params} onChange={(evt)=>{this.onInputInCompanyChange(evt.target.value)}} label='请选择企业'/ >}
                      onChange={this.onCompanyChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth
                      required
                      id="username"
                      label="用户名（英文字母或与数字组合，建议使用姓名全拼）"
                      style={styles.TextField}
                      onChange={this.onInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth
                      required
                      id="tel"
                      label="手机号码"
                      defaultValue=""
                      style={styles.TextField}
                      onChange={this.onInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth
                      required
                      id="email"
                      label="电子邮箱"
                      defaultValue=""
                      style={styles.TextField}
                      onChange={this.onInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth
                      required
                      id="password"
                      label="密码（长度不得少于8位）"
                      type="password"
                      onChange={this.onInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth
                      required
                      id="password1"
                      label="再次输入密码"
                      type="password"
                      onChange={this.onInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="roles-label">职务</InputLabel>
                      <Select
                        labelId="roles-label"
                        id="roles"
                        multiple
                        input={<OutlinedInput label="职务" />}
                        renderValue={(selected)=>selected.join(',')}
                        value={roles_selected}
                        fullWidth
                      >
                        { roles_list }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <LoadCanvasTemplateNoReload />
                    <TextField
                      required
                      id="captcha"
                      label="请输入图片中的字母和数字"
                    />
                  </Grid>
                  <Grid item xs={12} alignItems>
                    <Button 
                      style={styles.Button}
                      variant="contained"
                    >复位</Button>
                    <Button 
                      style={styles.Button}
                      variant="contained"
                      color="primary"
                      onClick={this.onRegisterClicked}
                    >注册</Button>
                    <Button 
                      style={styles.Button}
                      variant="contained"
                      color="secondary"
                      onClick={()=>{this.props.history.push('/login')}}
                    >已有帐号，请登录</Button>
                  </Grid>
                </Grid>    
            </Paper>
        )
    }
}

export default withRouter(Register);