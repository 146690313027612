import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import dateFormat from 'dateformat';
import { BACK_URL } from './Utils';
import {
    Card, CardContent, Typography, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, TableFooter
} from '@material-ui/core';


class ExamRecords extends Component {
    constructor(props) {
        super(props);
        this.state = {
            records: [],
        }
    }

    componentDidMount() {
        if (this.props.access==='') this.props.history.push('/login');
        this.fetch_records(null);
    }

    fetch_records = (url) => {
        const exam_id = this.props.location.state.examId;
        const tester_id = this.props.location.state.testerId;
        let qryStr = '';
        if (exam_id !== undefined && exam_id.toString() !== '') qryStr = qryStr + 'exam_id='+exam_id.toString();
        if (qryStr!=='') qryStr = qryStr + '&';
        if (tester_id!==undefined && tester_id.toString() !=='') qryStr = qryStr + 'tester_id='+tester_id.toString(); 
        if (qryStr!=='') qryStr = '?' + qryStr;
        var myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        fetch(url===null?BACK_URL+'exam_records/'+qryStr:url, {headers: myHeaders})
            .then(res=>res.json())
            .then(data=>{
                this.setState({records: data,})
            })
            .catch(error=>{
                console.log(error);
            })
    }

    componentDidUpdate() {
        if (!this.props.access) this.props.history.push('/login');
    }

    viewRecordClicked = (exam_rd_id) => {
        this.props.history.push({
            pathname: '/exam_paper',
            state: {exam_rd_id: exam_rd_id,}
        });
    }

    render() {
        let records = this.state.records;
        let results = [];
        if (records.results !== undefined && records.results.length > 0) {
            records.results.forEach((r)=>{
                results.push(
                    <TableRow key={r.id}>
                        <TableCell>
                            <Button variant="text" onClick={()=>this.viewRecordClicked(r.id)}>
                            {r.exam_name}</Button>
                        </TableCell>
                        <TableCell>{r.tester_name}</TableCell>
                        <TableCell>{r.tester_company}</TableCell>
                        <TableCell>{r.exam_type}</TableCell>
                        <TableCell>{dateFormat(r.begin_time,"yy-mm-dd H:MM")}</TableCell>
                        <TableCell>{r.end_time?dateFormat(r.end_time,"yy-mm-dd H:MM"):''}</TableCell>
                        <TableCell>{r.score}</TableCell>
                        <TableCell>{r.is_pass?'√':'×'}</TableCell>
                    </TableRow>
                )
            });
        }
        return (
            <div style={{width:'80%', margin:'auto'}}>
                <Card variant="outlined" style={{marginTop:10}}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            练习/考试 记录
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>考试名称</TableCell>
                                        <TableCell>参加人</TableCell>
                                        <TableCell>所在单位</TableCell>
                                        <TableCell>类型</TableCell>
                                        <TableCell>开始时间</TableCell>
                                        <TableCell>结束时间</TableCell>
                                        <TableCell>分数</TableCell>
                                        <TableCell>是否合格</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { results }
                                </TableBody>
                                <TableFooter>
                                    <TableCell colSpan={4}>
                                        <Button variant='text' disabled={!records.previous} onClick={()=>this.fetch_records(records.previous)}>上一页</Button>
                                    </TableCell>
                                    <TableCell colSpan={4}>
                                        <Button variant='text' disabled={!records.next} onClick={()=>this.fetch_records(records.next)}>下一页</Button>
                                    </TableCell>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </div>
        )
    }
}

export default withRouter(ExamRecords);