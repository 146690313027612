import React, { Component } from 'react';
import dateFormat from 'dateformat';
import { withRouter } from 'react-router-dom';
import {  BACK_URL } from './Utils';
import {
    Card, CardContent, Typography, Paper,
    Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow, Button
} from '@material-ui/core';

import ReactGA from 'react-ga';


class UserHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exams: [],
            exams_enrolls: [],
            is_enrolled: false,
        }
    }

    componentDidMount() {
        this.fetchExams();
    }

    fetchExams = () => {
        var myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        fetch(BACK_URL + 'exams/?oncoming=1', {headers: myHeaders})
            .then(res=>{ if (res.ok) {
                return res.json()
            } else {
                this.props.history.push('/login');
            }})
            .then(res =>{
                this.setState({exams:res.results,});
            })
            .catch(err=>{
                console.log(err);
            })
    }

    componentDidUpdate() {
        if (!this.props.access) this.props.history.push('/login');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    enrollExam = (id) => {
        var myHeaders = new Headers();
        myHeaders.append('Content-Type','application/json')
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        const options = {
            method: 'POST',
            body: JSON.stringify({
                exam: id,
                tester: this.props.tester_id
            }),
            headers: myHeaders
        };
        fetch(BACK_URL+'exam_enroll_create/', options)
            .then(res=>{
                if (!res.ok) {
                    throw new Error("HTTP status " + res.status);
                } else {
                    //this.setState({is_enrolled: true});
                    //this.props.history.push('/userhome');
                    this.fetchExams();
                }
            })
    }

    enrollClicked = (id, role) => {
        const roles = this.props.roles;
        let ok = false;
        roles.split(',').forEach(r=>{
            role.toString().split(',').forEach(r2=>{
                if (r === r2) ok=true;
            })
        });
        if (!ok) {
            alert('您不具备报名参加此考试的资格。');
            return;
        }
        this.enrollExam(id);
    }

    examClicked = (id) => {
        let duration = 1;
        let in_exam = false;
        const exams = this.state.exams;
        exams.forEach(exam=>{
            if (exam.id === id) {
                duration = exam['duration'];
                in_exam = exam['in_judge'];
            }
        });
        //console.log(duration);
        this.props.history.push({
            pathname: '/exam',
            state: {examId: id, duration: duration, in_exam: in_exam}
        });
    }

    viewExamRecord = (id) => {
        this.props.history.push({
            pathname: '/exam_records',
            state: {examId: id, testerId: this.props.tester_id}
        })
    }

    render() {
        if (this.props.is_staff === true) this.props.history.push('/adminhome');
        let exams = this.state.exams;
        let results = [];
        if (exams !== undefined && exams.length > 0) {
            exams.forEach(exam=>{
                let is_enroll = '否';
                let operation = '';
                let is_pass = '否'
                if (exam.is_pass) is_pass='是';
                if (exam.is_enrolled) {
                    is_enroll = '是';
                    if (exam.in_mock) {
                        operation=(<span>
                            <Button key={exam.id} variant="text" onClick={()=>this.examClicked(exam.id)}>练习</Button>
                            {exam.has_record?<Button key={exam.id+'1'} variant="text" onClick={()=>this.viewExamRecord(exam.id)}>记录</Button>:''}
                            </span>);
                    } else if (exam.in_judge) {
                        operation=(<span>
                            {!exam.is_excess ? <Button key={exam.id} variant="text" onClick={()=>this.examClicked(exam.id)}>考试</Button> : ''}
                            {exam.has_record?<Button key={exam.id+'1'} variant="text" onClick={()=>this.viewExamRecord(exam.id)}>记录</Button>:''}
                        </span>);
                    } else {
                        operation=(<Button key={exam.id} variant="text" onClick={()=>this.viewExamRecord(exam.id)}>记录</Button>);
                    }
                } else { if (exam.in_judge || exam.in_mock) {
                        operation=(<Button key={exam.id} variant="text" onClick={()=>this.enrollClicked(exam.id,exam.role1)}>我要考试</Button>);
                    }
                }

                // 注意：此处假设考试中只有一种企业类型
                let role = exam.role1;
                const company_type = role.split('-')[0] + '-';
                role = role.slice(0, 1) + role.slice(1).replaceAll(company_type, '');

                results.push(
                    <TableRow key={exam.id}>
                        <TableCell>{exam.name}</TableCell>
                        <TableCell>{role}</TableCell>
                        <TableCell>{dateFormat(exam.mock_exam_begin,"yy-mm-dd")}</TableCell>
                        <TableCell>{dateFormat(exam.mock_exam_end,"yy-mm-dd")}</TableCell>
                        <TableCell>{dateFormat(exam.exam_begin,"yy-mm-dd")}</TableCell>
                        <TableCell>{dateFormat(exam.exam_end,"yy-mm-dd")}</TableCell>
                        <TableCell>{exam.duration}</TableCell>
                        <TableCell>{is_enroll}</TableCell>
                        <TableCell>{is_pass}</TableCell>
                        <TableCell>{operation}</TableCell>
                    </TableRow>
                )
            })
        }
        return(
            <div style={{width:'90%', margin:'auto'}}>
                <Card variant="outlined" style={{marginTop:10}}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            目前开放的考试
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>名称</TableCell>
                                        <TableCell>参加人员</TableCell>
                                        <TableCell>练习开始时间</TableCell>
                                        <TableCell>练习结束时间</TableCell>
                                        <TableCell>考试开始时间</TableCell>
                                        <TableCell>考试结束时间</TableCell>
                                        <TableCell>时长(分钟)</TableCell>
                                        <TableCell>是否已报名</TableCell>
                                        <TableCell>是否已通过</TableCell>
                                        <TableCell>操作</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {results}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </div>            
        )
    }
}

export default withRouter(UserHome);