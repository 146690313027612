import React, { Component } from "react";
import { BACK_URL } from "./Utils";
import dateFormat from "dateformat";
import { 
    Grid, 
    Paper, 
    Card,
    Typography, 
    Table,
    Button,
    CardContent,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableFooter
} from '@material-ui/core';
import { withRouter } from "react-router";


class UserInfo extends Component {
    state = {
        exam_records: [],
    }

    componentDidMount() {
        this.fetch_records(null);
    }

    fetch_records = (url=null) => {
        if (!this.props.is_staff) {
            const user_id = this.props.tester_id;
            var myHeaders = new Headers();
            myHeaders.append('Content-Type','application/json')
            myHeaders.append('Authorization','Bearer ' + this.props.access);
            fetch(url===null?BACK_URL+'exam_records/?tester_id='+user_id:url, {headers:myHeaders})
            .then(res=>res.json())
            .then(data=>{
                //console.log(data);
                this.setState({exam_records: data,})
            })
            .catch(error=>console.log(error));
        }
    }

    componentDidUpdate() {
        if (!this.props.access) this.props.history.push('/login');
    }

    viewRecordClicked = (exam_rd_id) => {
        this.props.history.push({
            pathname: '/exam_paper',
            state: {exam_rd_id: exam_rd_id,}
        });
    }

    modifyInfoClicked = () => {
        this.props.history.push({
            pathname: '/change_user_info',
            state: {tester_id: this.props.tester_id,}
        })
    }

    render() {
        const records = this.state.exam_records;
        let results = [];
        if (records.results !== undefined && records.results.length > 0) {
            records.results.forEach((r)=>{
                results.push(
                    <TableRow key={r.id}>
                        <TableCell>
                            <Button variant="text" onClick={()=>this.viewRecordClicked(r.id)}>
                            {r.exam_name}</Button>
                        </TableCell>
                        <TableCell>{r.tester_name}</TableCell>
                        <TableCell>{r.tester_company}</TableCell>
                        <TableCell>{r.exam_type}</TableCell>
                        <TableCell>{dateFormat(r.begin_time,"yy-mm-dd H:MM")}</TableCell>
                        <TableCell>{r.end_time?dateFormat(r.end_time,"yy-mm-dd H:MM"):''}</TableCell>
                        <TableCell>{r.score}</TableCell>
                        <TableCell>{r.is_pass?'√':'×'}</TableCell>
                    </TableRow>
                )
            });
        }

        let roles = this.props.roles;
        // 注意：此处假设考试中只有一种企业类型
        const company_type = roles.split('-')[0] + '-';
        roles = roles.slice(0, 1) + roles.slice(1).replaceAll(company_type, '');

        return (
            <div style={{width:'90%', margin:'auto'}}>
                <Card variant="outlined" style={{marginTop:10, paddingBottom:10}}>
                    <CardContent>
                    <Grid container>
                        <Grid item xs={6} sm={6}>
                            <Typography gutterBottom variant="h6" component="div" style={{margin:10}} display="block">
                                <span style={{marginRight:10}}>单位:</span><u>{this.props.company}</u>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Button variant="outlined" onClick={this.modifyInfoClicked}>修改我的信息</Button>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Typography gutterBottom variant="h6" component="div" style={{margin:10}} display="block">
                                <span style={{marginRight:10}}>姓名:</span><u>{this.props.name}</u>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Typography gutterBottom variant="h6" component="div" style={{margin:10}} display="block">
                                <span style={{marginRight:10}}>职务:</span><u>{roles}</u>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>考试名称</TableCell>
                                            <TableCell>参加人</TableCell>
                                            <TableCell>所在单位</TableCell>
                                            <TableCell>类型</TableCell>
                                            <TableCell>开始时间</TableCell>
                                            <TableCell>结束时间</TableCell>
                                            <TableCell>分数</TableCell>
                                            <TableCell>是否合格</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { results }
                                    </TableBody>
                                    <TableFooter>
                                        <TableCell colSpan={2}>
                                            {records.count}个结果
                                        </TableCell>
                                        <TableCell colSpan={3}>
                                            <Button variant="text" disabled={!records.previous} onClick={()=>this.fetch_records(records.previous)}>上一页</Button>
                                        </TableCell>
                                        <TableCell colSpan={3}>
                                            <Button variant="text" disabled={!records.next} onClick={()=>this.fetch_records(records.next)}>下一页</Button>
                                        </TableCell>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    </CardContent>
                </Card>
            </div>
        )
    }
}

export default withRouter(UserInfo);