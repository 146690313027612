import React, { Component } from "react";
import { BACK_URL } from "./Utils";
//import dateFormat from "dateformat";
import { 
    Grid, 
    Card,
    Typography, 
    CardContent,
    List,
    ListItem,
    ListItemButton,
    Box,
    Button
} from '@material-ui/core';
import { withRouter } from "react-router";

class UserRole extends Component {
    state = {
        roles_raw: [],
        optional_roles: [],
        roles_selected: [],
    }

    componentDidMount() {
        var tmpList = this.props.roles.split(',');
        this.setState({roles_selected:tmpList});
        var myHeaders = new Headers();
        myHeaders.append('Content-Type','application/json')
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        fetch(BACK_URL+'role_list/',{headers:myHeaders})
        .then(res=>res.json())
        .then(res=>{
          var roles = [];
          var roles_raw = [];
          res.results.forEach(r=>{
              roles_raw.push({'role':r.company_type+'-'+r.name,'id':r.id,});
              if (!tmpList.includes(r.company_type+'-'+r.name)) {
                  roles.push(r.company_type+'-'+r.name);
              }
          });
          this.setState({roles_raw: roles_raw,optional_roles:roles,})
        })
        .catch(err=>console.log(err));
    }

    componentDidUpdate() {
        if (!this.props.access) this.props.history.push('/login');
    }

    optionalRoleClicked = (val) => {
        var optionalRoles = this.state.optional_roles;
        var roles_selected = this.state.roles_selected;
        optionalRoles = optionalRoles.filter(value => value !== val);
        if (!roles_selected.includes(val)) roles_selected.push(val);
        this.setState({
            optional_roles:optionalRoles,
            roles_selected:roles_selected,
        });
    }

    selectedRoleClicked = (val) => {
        var optionalRoles = this.state.optional_roles;
        var roles_selected = this.state.roles_selected;
        roles_selected = roles_selected.filter(value => value !== val);
        if (!optionalRoles.includes(val)) optionalRoles.push(val);
        this.setState({
            optional_roles:optionalRoles,
            roles_selected:roles_selected,
        });
    }

    saveClicked = () => {
        const roles = this.state.roles_selected.join(',');
        if (roles.length <= 0) {
            alert('选择的职务/角色不可为空。');
            return;
        }
        const options = {
            method: 'POST',
            body: JSON.stringify({
                tester_id: this.props.tester_id,
                roles: roles,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        };
        fetch(BACK_URL+'update_tester_roles/', options)
          .then(res=>{
              if (!res.ok) {
                return res.text().then(text => { throw new Error(text) });
              }
          })
          .then(res => {
              alert('修改职务/角色成功，您需要重新登录。');
              this.props.onLogout();
          })
    }

    render() {
        let roles = [];
        let roles_selected = [];
        this.state.optional_roles.forEach((role)=>{
            roles.push(
                <ListItem key={role}>
                    <ListItemButton onClick={()=>this.optionalRoleClicked(role)}>{role}</ListItemButton>
                </ListItem>
            )
        });
        this.state.roles_selected.forEach((role)=>{
            roles_selected.push(
                <ListItem key={role}>
                    <ListItemButton onClick={()=>this.selectedRoleClicked(role)}>{role}</ListItemButton>
                </ListItem>
            )
        });

        let _roles = this.props.roles;
        // 注意：此处假设考试中只有一种企业类型
        const company_type = _roles.split('-')[0] + '-';
        _roles = _roles.slice(0, 1) + _roles.slice(1).replaceAll(company_type, '');

        return (
            <div style={{width:'90%', margin:'auto'}}>
                <Card variant="outlined" style={{marginTop:10, paddingBottom:10}}>
                    <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Typography gutterBottom variant="h6" component="div" style={{margin:10}} display="block">
                                <span style={{marginRight:10}}>单位:</span><u>{this.props.company}</u>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Typography gutterBottom variant="h6" component="div" style={{margin:10}} display="block">
                                <span style={{marginRight:10}}>姓名:</span><u>{this.props.name}</u>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Typography gutterBottom variant="h6" component="div" style={{margin:10}} display="block">
                                <span style={{marginRight:10}}>职务:</span><u>{_roles}</u>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}><hr/></Grid>
                        <Grid container item xs={12} sm={12} justifyContent="center" spacing={2}>
                            <Grid item xs={6}>
                                <Typography gutterBottom variant="h6" component="div" style={{margin:10}} display="block">
                                    可以选择的职务或角色：
                                </Typography>
                                <List component={Box} sx={{border:1,borderColor:'primary.main'}}>
                                    {roles}
                                </List>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom variant="h6" component="div" style={{margin:10}} display="block">
                                    您的职务或角色：
                                </Typography>
                                <List  component={Box} sx={{ border:1,borderColor:'primary.main'}}>
                                    {roles_selected}
                                </List>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" style={{margin:'5px'}} onClick={this.saveClicked}>保存</Button>
                        </Grid>
                    </Grid>
                    </CardContent>
                </Card>
            </div>
        )
    }
}

export default withRouter(UserRole);