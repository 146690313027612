import React, { Component } from "react";
import { withRouter } from "react-router";
import dateFormat from "dateformat";
import { BACK_URL } from "./Utils";
import { 
    Grid, 
    Paper, 
    Card,
    Typography, 
} from '@material-ui/core';


class Topic extends Component {

    render() {
        //console.log('answer:',this.props.answer);
        //console.log('user answer:', this.props.user_answer);
        const answer_style = Array.from(this.props.answer).sort().join() === Array.from(this.props.user_answer).sort().join() ? { color: 'green' } : { color: 'red' };
        
        //console.log(this.props);
        return (
            <Paper style={{marginTop:10,marginLeft:10,marginRight:10,padding:10}} square>
                <Typography variant="body1" display="block" gutterBottom>
                    { this.props.index + '.'}
                    <span style={{padding:2, marginLeft:5, marginRight:5, color:'red', border:'solid', borderWidth:1}}>
                        { this.props._type }
                    </span>
                    { this.props.description + '(' + this.props.answer + ')  考生答案：' }
                    <span style={answer_style}>{ this.props.user_answer }</span>
                </Typography>
                <div style={{paddingLeft:10}}>
                     {'A.' + this.props.A }
                </div>
                <div style={{paddingLeft:10}}>
                     {'B.' + this.props.B }
                </div>
                <div style={{paddingLeft:10}}>
                     {this.props.C ? ('C.' + this.props.C) : '' }
                </div>
                <div style={{paddingLeft:10}}>
                     {this.props.D ? ('D.' + this.props.D) : '' }
                </div>
                <div style={{paddingLeft:10}}>
                     {this.props.E ? ('E.' + this.props.E) : '' }
                </div>
                <div style={{paddingLeft:10}}>
                     {this.props.F ? ('F.' + this.props.F) : '' }
                </div>
                <div style={{paddingLeft:10}}>
                    { '出处：' + this.props.source + '，条款：' + this.props.clause }
                </div>
            </Paper>
        )
    }
}

class ExamPaper extends Component {
    state = {
        exam_record: {},
        photos: [],
        topics: [],
    }

    componentDidMount() {
        if (!this.props.access) this.props.history.push('/login');
        let exam_record_id = 0;
        exam_record_id = this.props.location?.state?.exam_rd_id;
        if (exam_record_id === 0) {
            this.props.history.push('/login');
        }        
        var myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        fetch(BACK_URL + 'exam_record/'+exam_record_id+'/', {headers: myHeaders})
            .then(res=>res.json())
            .then(data=>{
                this.setState({exam_record: data,});
                this.getTopics(data.topics);
            })
            .catch(error=>{
                console.log(error);
            });
        fetch(BACK_URL + 'exam_record_photos/' + exam_record_id + '/', {headers: myHeaders})
          .then(res=>res.json())
          .then(data=>{this.setState({photos: data})})
          .catch(error=>{
              console.log(error)
          });
    }

    componentDidUpdate() {
        if (!this.props.access) this.props.history.push('/login');
    }

    getTopics = (ids) => {
        var myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        fetch(BACK_URL + 'questions/?ids=' + ids , {headers: myHeaders})
            .then(res=>res.json())
            .then(data=>{
                this.setState({topics: data.results,})
            })
            .catch(error=>{
                console.log(error);
            });
    }

    render() {
        const topics = this.state.topics;
        const photos = this.state.photos;
        const exam_rd = this.state.exam_record;
        let answers = [];
        if (exam_rd.answers) answers = exam_rd.answers.split(',');
        let ids = [];
        if (exam_rd.topics) ids = exam_rd.topics.split(',');
        const topics_list = [];
        let photo_url = '';
        if (photos !== undefined && photos.length > 0) photo_url = photos[0].photo;
        if (topics !==  undefined && topics.length > 0) {
            ids.forEach((id, index) => {
                //console.log('answers:',exam_rd);
                let topic = {};
                index = index + 1;
                topic = topics.find(x => x.id === parseInt(id));
                if (topic) topics_list.push(
                    <Topic 
                        key={topic.id} 
                        user_answer={answers[index-1]} 
                        index={index} 
                        {...topic} 
                    />
                )
            });
        }
        return (
            <div style={{width:'80%', margin:'auto'}}>
                <Card variant="outlined" style={{marginTop:10, paddingBottom:10}}>
                <Grid container>                  
                        <Grid item xs={12}sm={12} md={7}>
                            <Typography gutterBottom variant="h6" component="div" style={{margin:10}} display="block">
                                <span style={{marginRight:10}}>名称:</span><u>{exam_rd.exam_name}</u>
                            </Typography>
                            <Typography gutterBottom variant="h6" component="div" style={{margin:10}} display="block">
                                <span style={{marginRight:10}}>单位:</span><u>{exam_rd.tester_company}</u>
                            </Typography>
                            <Typography gutterBottom variant="h6" component="div" style={{margin:10}}>
                            <span style={{marginRight:10}}>姓名:</span><u>{exam_rd.tester_name}</u>
                            </Typography>
                            <Typography gutterBottom variant="h6" component="div" style={{margin:10}}>
                                时间：<u>{dateFormat(exam_rd.begin_time,"yyyy-mm-dd H:MM")}</u> — <u>{dateFormat(exam_rd.end_time,"yyyy-mm-dd H:MM")}</u>
                            </Typography>
                            <Typography gutterBottom variant="h6" component="div" style={{margin:10}}>
                            <span style={{marginRight:10}}>得分:</span><u>{exam_rd.score}</u>
                            </Typography>
                            <Typography gutterBottom variant="h6" component="div" style={{margin:10}}>
                            <span style={{marginRight:10}}>结果:</span><u>{exam_rd.is_pass?<span style={{color:'green'}}>通过</span>:<span style={{color:'red'}}>不合格</span>}</u>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                            <img id="imgCaptured" style={{margin:5, float: 'right'}} src={photo_url} alt="captured" width={320}/>
                        </Grid>
                        <Grid item xs={12} sm={12}><hr /></Grid>
                        <Grid item xs={12} sm={12}>
                            { topics_list } 
                        </Grid>                    
                </Grid>
                </Card>
            </div>
        )
    }
}

export default withRouter(ExamPaper);