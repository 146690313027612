import React, { Component } from "react";
import { BACK_URL } from "./Utils";
import { Grid, Paper, Card, Typography, Table, Button, CardContent, TextField } from '@material-ui/core';
import { withRouter } from 'react-router';
import { areIntervalsOverlappingWithOptions } from "date-fns/fp";


class ChangeUserInfo extends Component {
    state = {
        company: '',
        name: '',
        tel: '',
        email: '',
        username: '',
    }

    get_userinfo = () => {
        if (!this.props.is_staff) {
           this.setState({
            company: this.props.company,
            name: this.props.name,
            tel: this.props.tel,
            email: this.props.email,
            username: this.props.username,
           })
        }
    }

    componentDidMount() {
        this.get_userinfo();
    }

    componentDidUpdate() {
        if (!this.props.access) this.props.history.push('/login');
    }

    fetchNewPasswd = (passwd) => {
        var myHeaders = new Headers();
        myHeaders.append('Content-Type','application/json')
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        fetch(BACK_URL+'new_passwd/'+passwd+'/', {headers:myHeaders})
        .then(res=>res.json())
        .then(data=>{
            //console.log(data['passwd']);
            if (data['passwd']===passwd) {
                alert("您的密码已经修改，请使用新密码重新登录。");
                this.props.onLogout();
            }
        })
        .catch(error=>console.log(error));
    }

    onChangPasswdClicked = () => {
        if (window.confirm("您想要更改密码吗？你需要输入两次相同的新密码。") == true) {
            var passwd1 = prompt("请输入新密码（不少于6个字符）：",'');
            if (passwd1.trim()==='') return;
            if (passwd1.length < 6) {
                alert("您输入的新密码应不少于6个字符，操作取消。");
                return;
            }
            var passwd2 = prompt("请再次输入新密码（与上次输入相同）：",'');
            if (passwd1 !== passwd2) {
                alert("两次输入的密码不一样，操作取消。");
                return;
            }
            this.fetchNewPasswd(passwd1);
        } else return;
    }

    onInputChange = (evt) => {
        const id = evt.target.id;
        this.setState({[id]: evt.target.value.trim(),});
    }

    onSaveClicked = () => {
        const email = this.state.email;
        const tel = this.state.tel;
        if (email.trim()==='' || tel.trim()==='') return;
        if (tel.length !== 11) {
            alert('手机号码长度不符合要求，应为11位。');
            return;
        }
        var myHeaders = new Headers();
        myHeaders.append('Content-Type','application/json')
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        fetch(BACK_URL+'update_email_or_tel/'+email+'/'+tel+'/', {headers:myHeaders})
        .then(res=>res.json())
        .then(data=>{
            if (data['tel'] || data['email']) {
                alert('信息已更新，请重新登录。');
                this.props.onLogout();
            }
        })
        .catch(error=>console.log(error));
    }

    render() {
      return (
        <div style={{width:'90%',margin:'auto'}}>
            <Card variant="outlined" style={{marginTop:10, paddingBottom:10}}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Typography gutterBottom variant="h6" component="div" style={{margin:10}} display="block">
                                <span style={{marginRight:10}}>单位:</span><u>{this.props.company}</u>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography gutterBottom variant="h6" component="div" style={{margin:10}} display="block">
                                <span style={{marginRight:10}}>姓名:</span><u>{this.props.name}</u>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography gutterBottom variant="h6" component="div" style={{margin:10}} display="block">
                                <span style={{marginRight:10}}>账号:</span><u>{this.props.username}</u>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography gutterBottom variant="h6" component="div" style={{margin:10}} display="block">
                                <span style={{marginRight:10}}>职务:</span><u>{this.props.roles}</u>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} style={{paddingTop:5,paddingBottom:10}}>
                            <TextField id="tel" label="电话" variant="outlined" onChange={this.onInputChange} value={this.state.tel} />
                        </Grid>
                        <Grid item xs={12} sm={12} style={{paddingBottom:10}}>
                            <TextField id="email" label="邮箱" variant="outlined" onChange={this.onInputChange} value={this.state.email}/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Button variant="contained" color="error" onClick={this.onChangPasswdClicked}>修改密码</Button>
                            <Button variant="contained" style={{marginLeft:5}} color="success" disabled={this.state.tel === this.props.tel && this.state.email === this.props.email} onClick={this.onSaveClicked}>保存</Button>
                            <Button variant="contained" style={{marginLeft:5}} onClick={()=>this.props.history.push('/userhome')}>返回</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
      )
    }
}

export default withRouter(ChangeUserInfo);