import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { BACK_URL } from './Utils';
import {
    Card, CardContent, Paper, 
} from '@material-ui/core';
import {
    Chart as ChartJS,
  } from 'chart.js';
import { CategoryScale } from 'chart.js'; 
import { Bar } from 'react-chartjs-2';
import { Chart, registerables} from 'chart.js';

Chart.register(...registerables);

ChartJS.register(CategoryScale);

class EnrollStatistics extends Component {
    
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            stastics: [],
        }
    }

    componentDidMount() {
        if (this.props.access === '') this.props.history.push('/login');
        const exam_id = this.props.location.state.exam_id;
        var myHeaders = new Headers();
        myHeaders.append('Authorization','Bearer ' + this.props.access);
        fetch(BACK_URL + 'get_exam_statistics/' + exam_id + '/')
          .then(res=>{
              if (res.ok) {
                  return res.json()
              } else {
                  alert('Error');
              }
          })
          .then(res=>{
              res.sort((a,b)=>(a.cred_no>b.cred_no)?1:-1);
              this.setState({stastics: res});
          })
          .catch(err=>{
              console.log(err);
          })
    }

    componentDidUpdate() {
        if (!this.props.access) {
            this.props.history.push('/login');
        }
    }

    render() {
        const title = this.props.location.state.name;
        const labels = [];
        const data_passed = [];
        const data_not_passed = [];
        this.state.stastics.forEach(sta=>{
                labels.push(sta.cname);
                data_passed.push(sta.passed);
                data_not_passed.push(sta.not_passed);
            });
        const options = {
            plugins: {
              title: {
                display: true,
                text: title + '报名及考试情况',
              },
            },
            responsive: true,
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
              },
            },
        };
        const data1 = {
            labels,
            datasets: [
                {
                    label: '通过考试',
                    data: data_passed,
                    backgroundColor: 'rgb(75, 192, 192)',
                },
                {
                    label: '未通过考试',
                    data: data_not_passed,
                    backgroundColor: 'rgb(255, 99, 132)',
                }
            ]
        }

        return (
            <Card variant="outlined" style={{width:'80%', margin:'auto'}}>
                <CardContent>
                    <Paper>
                        <Bar options={options} data={data1} />
                    </Paper>
                </CardContent>
            </Card>
        );
    }

}

export default withRouter(EnrollStatistics);